define("admin/components/custom-select", ["exports", "admin/mixins/testable", "admin/mixins/labelable", "admin/mixins/clicked-outside", "admin/utils/overridable-computed"], function (_exports, _testable, _labelable, _clickedOutside, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend(_testable.default, _labelable.default, _clickedOutside.default, {
    classNames: ['dropdown'],
    visible: false,
    value: null,
    highlighted: null,
    labelAttribute: Ember.computed.alias('value'),
    actions: {
      select: function select(option) {
        this.set('value', option);
        this.set('visible', false);

        if (this.didSelect) {
          this.didSelect(option);
        }
      },
      keyDown: function keyDown(event) {
        this.set('visible', true); // up 38

        if (event.which === 38) {
          this.move(-1);
        } // down 40
        else if (event.which === 40) {
            this.move(+1);
          } // select 13
          else if (event.which === 13) {
              // if a selection is made, send it
              var selection = this.value;

              if (selection) {
                this.send('select', selection);
              }
            } // escape 27
            else if (event.which === 27) {
                this.set('visible', false);
              }

        return false;
      }
    },
    valueTitle: Ember.computed('optionsArray.@each', 'value', function () {
      var _this = this;

      var current;

      if (this.value) {
        current = this.optionsArray.find(function (option) {
          if (_typeof(_this.value) === 'object') {
            return _this.value.get('id') === option.value.get('id');
          } else {
            return option.value === _this.value;
          }
        });
      }

      if (current && current.title) {
        return current.title;
      } else {
        return this.placeholder || 'Select';
      }
    }),
    placeholderVisible: Ember.computed('valueTitle', function () {
      return this.valueTitle === (this.placeholder || 'Select');
    }),
    move: function move(direction) {
      var selection = this.value;
      var options = this.optionsArray;

      if (!selection) {
        selection = options.firstObject;
        this.set('value', selection.value);
      } else {
        var idx = options.indexOf(selection);

        if (direction === -1 && idx > 0 || direction === 1 && idx <= options.length) {
          selection = options.objectAt(idx + direction);
          this.set('value', selection.value);
        }
      }
    },
    optionsArray: (0, _overridableComputed.default)('optionsArray', 'options', function () {
      var options = this.options;

      if (Ember.typeOf(options) === 'array') {
        return options;
      } else {
        return Object.keys(options).map(function (k) {
          return {
            title: options[k],
            value: k
          };
        });
      }
    }),

    /*
     * ClickedOutsideMixin
     */
    outsideClickTriggerElement: function outsideClickTriggerElement() {
      return this.$('.js-anchor');
    },
    didClick: function didClick() {
      this.set('visible', true);
    },
    didClickOutside: function didClickOutside() {
      this.set('visible', false);
    }
  });

  _exports.default = _default;
});