define("admin/templates/components/overlays/notification-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7AImlcii",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"notification notification--\",[24,[\"model\",\"style\"]]]]],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"message\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/overlays/notification-banner.hbs"
    }
  });

  _exports.default = _default;
});