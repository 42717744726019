define("admin/routes/main/support/account-details", ["exports", "admin/mixins/refreshable-route"], function (_exports, _refreshableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, {
    client: Ember.inject.service(),
    model: function model(params) {
      return this.client.cached('GET', "admin/support/account-details?account_id=".concat(params.account_id));
    }
  });

  _exports.default = _default;
});