define("admin/controllers/main/super/devops/redis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    actions: {
      searchRedisKeys: function searchRedisKeys() {
        var _this = this;

        return this.client.GET("admin/devops/search-redis-keys?pattern=".concat(this.pattern)).then(function (resp) {
          _this.set('keysOutput', resp.keys);
        });
      },
      getRedisKey: function getRedisKey() {
        var _this2 = this;

        return this.client.GET("admin/devops/redis-key?key=".concat(this.key)).then(function (resp) {
          _this2.set('output', resp.value || resp.error);
        });
      }
    }
  });

  _exports.default = _default;
});