define("admin/helpers/is-at-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAtIndex = isAtIndex;
  _exports.default = void 0;

  function isAtIndex(params
  /*, hash*/
  ) {
    var obj = params[0];
    var list = params[1];
    var index = params[2];
    return list.indexOf(obj) === index;
  }

  var _default = Ember.Helper.helper(isAtIndex);

  _exports.default = _default;
});