define("admin/utils/titleize", ["exports", "admin/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleize;

  function titleize(string) {
    return (0, _humanize.default)(string).replace(/(\w+)/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  }
});