define("admin/models/label", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/taggable", "admin/mixins/tokenable"], function (_exports, _model, _model2, _taggable, _tokenable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, _tokenable.default, {
    externalSource: (0, _model.attr)(),
    name: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    parent: (0, _model.belongsTo)('label', {
      inverse: null
    }),
    account: (0, _model.belongsTo)('account'),
    transactionCount: (0, _model.attr)()
  });

  _exports.default = _default;
});