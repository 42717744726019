define("admin/mixins/chart-custom-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    prettyTooltipOpts: function prettyTooltipOpts() {
      return {
        backgroundColor: '#ffffff',
        titleFontColor: '#414141',
        bodyFontColor: '#414141',
        titleFontFamily: "'Karla', sans-serif",
        bodyFontFamily: "'Karla', sans-serif",
        titleFontSize: 14,
        bodyFontSize: 14,
        xPadding: 18,
        yPadding: 12,
        shadowBlur: 10,
        shadowColor: 'rgba(0,0,0,.2)',
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        borderColor: '#f3f3f3',
        borderWidth: 1
      };
    },
    customTooltipOpts: function customTooltipOpts(self) {
      return {
        enabled: false,
        custom: function custom(tooltipModel) {
          if (this._options.zipbooksHideTooltip) {
            return;
          }

          self.updateTooltip(tooltipModel, this._chart.canvas);
        }
      };
    },
    updateTooltip: function updateTooltip(model, canvas) {
      if (this.isDestroyed || this.isDestroying) return;
      this.set('tooltipData', {
        visible: model.opacity !== 0,
        context: model.body && model.body[0].lines[0],
        canvas: canvas,
        position: {
          x: model.caretX,
          y: model.caretY
        }
      });
    },
    click: function click(_evt) {
      if (this.get('tooltipData.visible')) {
        Ember.tryInvoke(this, 'chartWasClicked', [this.router, this.get('tooltipData.context')]);
      }
    }
  });

  _exports.default = _default;
});