define("admin/mixins/list-controller-selectable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    selected: Ember.computed.filter('collection.@each.isSelected', function (model
    /*, index, array*/
    ) {
      return model.get('isSelected');
    }),
    actions: {
      toggleCheckAll: function toggleCheckAll(checked) {
        this.collection.forEach(function (item) {
          item.set('isSelected', checked);
        });
      },
      // this allows selecting multiple items at once with shift. Imagine a list of items 1-50, if you select 20,
      // hit shift and select 40, it will select 20, 40 and all items in between.
      itemSelected: function itemSelected(model, e) {
        if (e.shiftKey && this.get('selected.length') > 0) {
          var firstFound = false;

          for (var i = 0; i < this.get('collection.length'); i++) {
            var item = this.collection.objectAt(i);

            if (item.get('isSelected')) {
              firstFound = true;
            } else if (item.get('id') === model.get('id')) {
              return;
            } else if (firstFound) {
              item.set('isSelected', true);
            }
          }
        }
      }
    },
    unselectAll: function unselectAll() {
      this.selected.forEach(function (l) {
        return l.set('isSelected', false);
      });
    }
  });

  _exports.default = _default;
});