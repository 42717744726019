define("admin/controllers/main/super/statements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    queryParams: ['direction', 'sort', 'statementMonth', 'searchText'],
    direction: 'desc',
    sort: 'net_income',
    statementMonth: moment().subtract(1, 'month').format('YYYY-MM'),
    actions: {
      selectMonth: function selectMonth(month) {
        this.set('statementMonth', month);
      },
      updateSearch: function updateSearch() {
        this.send('refreshRoute');
      },
      refresh: function refresh() {
        return this.client.send('PATCH', "admin/statements/".concat(this.statementMonth, "/refresh"));
      }
    },
    months: Ember.computed(function () {
      var months = [];
      var date = moment('2018-12');

      while (date <= moment()) {
        months.push({
          value: date.format('YYYY-MM'),
          text: date.format('MMM YYYY')
        });
        date = date.add(1, 'month');
      }

      return months.reverse();
    })
  });

  _exports.default = _default;
});