define("admin/templates/components/needs-privilege", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRu1HZR+",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"oneBlock\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[24,[\"privileged\"]],[28,\"action\",[[23,0,[]],\"showPaidModal\",[24,[\"upgrade\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"privileged\"]]],null,{\"statements\":[[0,\"    \"],[14,2,[[28,\"action\",[[23,0,[]],\"showPaidModal\",[24,[\"upgrade\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/needs-privilege.hbs"
    }
  });

  _exports.default = _default;
});