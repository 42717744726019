define("admin/services/sibling-client", ["exports", "admin/services/client"], function (_exports, _client) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _client.default.extend({
    session: Ember.inject.service('sibling-session'),
    urlCache: Ember.inject.service('sibling-url-cache'),
    headers: function headers(_opts) {
      var headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      };
      var token = this.get('session.token');

      if (token) {
        headers['Authorization'] = "Bearer ".concat(token);
      }

      return headers;
    }
  });

  _exports.default = _default;
});