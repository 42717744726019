define("admin/components/tool-tip", ["exports", "admin/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'span',
    classNames: ['tooltip-element'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (_this.for) {
          var tooltip = _this.$();

          var subject = $('#' + _this.for);
          tooltip.css('width', subject.css('width'));
          tooltip.css('height', subject.css('height'));
          tooltip.css('position', 'absolute');
          tooltip.offset(subject.offset());
        }
      });
    },
    click: function click() {
      Ember.tryInvoke(this, 'onClick');
    }
  });

  _exports.default = _default;
});