define("admin/templates/components/lazy-relationship-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7g0jWhD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"proxyValue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/lazy-relationship-input.hbs"
    }
  });

  _exports.default = _default;
});