define("admin/components/drop-down", ["exports", "admin/mixins/testable", "admin/mixins/clicked-outside"], function (_exports, _testable, _clickedOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _clickedOutside.default, {
    classNames: ['dropdown-menu', 'animated', 'fadeIn'],
    classNameBindings: ['visible::hidden'],
    visible: false,
    clickInsideKeepOpen: false,

    /*
     * ClickedOutsideMixin
     */
    outsideClickTriggerElement: function outsideClickTriggerElement() {
      var id = this.for;
      var anchor = null;

      if (id) {
        anchor = $('#' + id);
      } else {
        anchor = this.$().prev();
      }

      this.set('anchor', anchor);
      return anchor;
    },
    checkIfClickWasOutside: function checkIfClickWasOutside(windowEvent) {
      var me = this.$()[0];
      var withinMe = windowEvent.target === me || me.contains(windowEvent.target);
      var clickedWithinAnchor = $(windowEvent.target).closest('a').length > 0;
      return !withinMe || clickedWithinAnchor && !this.clickInsideKeepOpen;
    },
    didClick: function didClick() {
      this.set('visible', true);

      if (this.onOpen) {
        this.onOpen();
      }
    },
    didClickOutside: function didClickOutside() {
      this.set('visible', false);

      if (this.onClose) {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});