define("admin/services/sibling-session", ["exports", "zipbooks/utils/push-payload", "admin/mixins/sessions"], function (_exports, _pushPayload, _sessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_sessions.default, {
    // when we have ui that lets an accountant be in one clients account and "peek" into
    store: Ember.inject.service('sibling-store'),
    client: Ember.inject.service('sibling-client'),
    // needs to be set manually by us
    token: null,
    currentUser: null,
    logout: function logout() {
      this.set('currentUser', null);
      this.store.unloadAll();
    },
    fetchLoggedInUser: function fetchLoggedInUser() {
      var _this = this;

      return this.client.GET('users', 'me').then(function (resp) {
        var user = (0, _pushPayload.default)(_this.store, 'user', resp); // set it as the sessions current user

        _this.set('currentUser', user);

        return user;
      });
    }
  });

  _exports.default = _default;
});