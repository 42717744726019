define("admin/helpers/first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.first = first;
  _exports.default = void 0;

  function first(params
  /*, hash*/
  ) {
    return params.find(function (p) {
      return p;
    });
  }

  var _default = Ember.Helper.helper(first);

  _exports.default = _default;
});