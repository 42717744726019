define("admin/mixins/highlightable-route", ["exports", "admin/utils/scrolling"], function (_exports, _scrolling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      highlight: {}
    },
    highlight: null,
    highlightClass: 'highlight',
    resetController: function resetController(controller
    /*isExiting, transition */
    ) {
      this._super.apply(this, arguments);

      controller.set('highlight', undefined);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        Ember.run.later(function () {
          var highlight = controller.get('highlight');
          var elementToHighlight = $("[data-highlight=\"".concat(highlight, "\"]"));

          if (elementToHighlight.length) {
            (0, _scrolling.immediateScroll)(elementToHighlight);
            elementToHighlight.addClass(_this.highlightClass);
          }
        }, 100);
      });
    }
  });

  _exports.default = _default;
});