define("admin/adapters/default", ["exports", "@ember-data/adapter/json-api", "admin/utils/request-log", "admin/config/environment", "admin/utils/redirect"], function (_exports, _jsonApi, _requestLog, _environment, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    host: _environment.default.API_URL || window.location.origin,
    namespace: 'v2',
    session: Ember.inject.service(),
    headers: Ember.computed('session.token', function () {
      var headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json' // include the token if present

      };
      var token = this.get('session.token');

      if (token) {
        headers['Authorization'] = "Bearer ".concat(token);
      } // return the headers


      return headers;
    }),
    // allows ember data to use a secondary auth token by adding it to the url. From what I can tell, there is
    // no way to get adapterOptions from the headers computed property, so this is kind of a hack since just replacing
    // the Authorization header for these specific requests would be ideal. So, this is a compromise since I cant
    // find any other way
    buildURL: function buildURL(_modelName, _id, snapshot, _requestType, _query) {
      var url = this._super.apply(this, arguments);

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.token) {
        var char = url.indexOf('?') === -1 ? '?' : '&';
        url += "".concat(char, "token=").concat(snapshot.adapterOptions.token);
      }

      return url;
    },
    handleResponse: function handleResponse(status, _headers, payload, request) {
      _requestLog.default.log(request.method, request.url, status, payload);

      if (status === 503) {
        (0, _redirect.default)('/maintenance');
      }

      if (status === 403) {
        Ember.getOwner(this).lookup('router:main').transitionTo('main.dashboard');
      }

      if (status === 402) {
        var paymentError = payload.errors.find(function (e) {
          return e.status === '402';
        });

        if (paymentError && paymentError.meta && paymentError.meta.needs) {
          Ember.getOwner(this).lookup('router:main').transitionTo('main.settings.subscription', {
            queryParams: {
              needs: paymentError.meta.needs
            }
          });
        }
      }

      if (status === 429 && payload.errors) {
        var emailVerifyError = payload.errors.find(function (e) {
          return e.id == 'email-not-verified';
        });

        if (emailVerifyError) {
          this.session.handleEmailNotVerified(emailVerifyError);
        }
      }

      return this._super.apply(this, arguments);
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll()
    /*store, snapshotRecordArray*/
    {
      return false;
    }
  });

  _exports.default = _default;
});