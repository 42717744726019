define("admin/utils/is-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isBrowser;
  _exports.browserNames = browserNames;
  _exports.operatingSystem = operatingSystem;
  _exports.hasCommandKey = hasCommandKey;
  _exports.hasControlKey = hasControlKey;

  function isBrowser() {
    var is = {
      // Opera 8.0+
      opera: !!window.opr && !!window.opr.addons || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
      // Firefox 1.0+
      firefox: typeof InstallTrigger !== 'undefined',
      // Safari 3.0+ "[object HTMLElementConstructor]"
      safari: /constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      }(!window['safari'] || window.safari.pushNotification),
      // Internet Explorer 6-11
      ie:
      /*@cc_on!@*/
      false || !!document.documentMode,
      // IE Edge
      edge: navigator.userAgent.indexOf('Edge') > -1,
      // iOS Google Chrome
      iOSChrome: navigator.userAgent.match('CriOS') // Chrome 1+

    };
    is.chrome = !!window.chrome && !!window.chrome.webstore && navigator.vendor === 'Google Inc.' && !is.opera && !is.ed || /HeadlessChrome/.test(window.navigator.userAgent); // Edge 20+

    is.edget = !is.ie && !!window.StyleMedia; // Blink engine detection

    is.blink = (is.chrome || is.opera) && !!window.CSS;
    return is;
  }

  function browserNames() {
    var browserIs = isBrowser();
    return Object.keys(browserIs).filter(function (browserName, _key) {
      return browserIs[browserName];
    });
  }

  function operatingSystem() {
    if (navigator.userAgent.match(/Android/i)) return 'Android';
    if (navigator.userAgent.match(/BlackBerry/i)) return 'BlackBerry';
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) return 'iOS';
    if (navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)) return 'Windows Mobile';
    if (navigator.appVersion.indexOf('Win') !== -1) return 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) return 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) return 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) return 'Linux';
    return 'Unknown';
  }

  function hasCommandKey() {
    return operatingSystem() == 'MacOS';
  }

  function hasControlKey() {
    return ['UNIX', 'Linux', 'Windows'].includes(operatingSystem());
  }
});