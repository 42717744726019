define("admin/components/dropdown-item-multi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'span',
    classNames: ['cursor-pointer', 'dropdown-item', 'dropdown-item-multi'],
    loading: false,
    click: function click() {
      var _this = this;

      this.set('loading', true);
      Ember.run.next(function () {
        Ember.run.scheduleOnce('afterRender', _this, function () {
          _this.router.transitionTo({
            queryParams: _this.queryParams
          }).then(function () {
            Ember.run.next(function () {
              if (!_this.isDestroyed && !_this.isDestroying) {
                _this.set('loading', false);
              }
            });
          });
        });
      });
    }
  });

  _exports.default = _default;
});