define("admin/overrides/array", [], function () {
  "use strict";

  Array.prototype.firstValue = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    for (var idx = 0; idx < this.length; idx++) {
      var next = this[idx];
      var found = callback.call(target, next, idx, this);

      if (found) {
        return found;
      }
    }

    return null;
  };
});