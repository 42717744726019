define("admin/controllers/main/super/admin-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    graphql: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newAdminEmail', '');
    },
    save: function save() {
      return this.graphql.send("\n      mutation UpdateAdmins($input: UpdateAdminsInput) {\n        updateAdmins(input: $input) {\n          admins {\n            super\n          }\n        }\n      }\n      ", {
        variables: {
          input: {
            admins: this.model.data.admins.edges.map(function (edge) {
              return {
                email: edge.node.identity.email,
                super: edge.node.super,
                support: edge.node.support
              };
            })
          }
        }
      });
    },
    actions: {
      deleteAdmin: function deleteAdmin(edge) {
        this.model.data.admins.edges.removeObject(edge);
        this.save();
      },
      updateAdmin: function updateAdmin(property, edge) {
        Ember.set(edge.node, property, !edge.node[property]);
        this.save();
      },
      addAdmin: function addAdmin() {
        var _this = this;

        this.model.data.admins.edges.pushObject({
          node: {
            identity: {
              email: this.newAdminEmail
            },
            support: false,
            super: false
          }
        });
        this.save().then(function () {
          _this.set('newAdminEmail', '');
        });
      }
    }
  });

  _exports.default = _default;
});