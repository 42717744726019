define("admin/utils/sentencize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sentencize;

  function sentencize(string) {
    string = string.charAt(0).toUpperCase() + string.slice(1);

    if (string.charAt(string.length - 1) !== '.') {
      string += '.';
    }

    return string;
  }
});