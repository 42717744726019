define("admin/models/bank-account", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/notificationable"], function (_exports, _model, _model2, _notificationable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_notificationable.default, {
    name: (0, _model.attr)(),
    balance: (0, _model.attr)('money'),
    currencyCode: (0, _model.attr)(),
    availableBalance: (0, _model.attr)('money'),
    zipbooksBalance: (0, _model.attr)('money'),
    unconfirmedTransactionsCount: (0, _model.attr)('integer'),
    connected: (0, _model.attr)('boolean'),
    externalId: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    maturesOn: (0, _model.attr)('datetime'),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    member: (0, _model.belongsTo)('bankMember')
  });

  _exports.default = _default;
});