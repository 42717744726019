define("admin/helpers/mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapping = mapping;
  _exports.default = void 0;

  function mapping(params, hash) {
    var value = params[0];
    return hash[value] || hash.default;
  }

  var _default = Ember.Helper.helper(mapping);

  _exports.default = _default;
});