define("admin/models/recurring-journal-entry", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/journal-entry/sorted-lines"], function (_exports, _model, _model2, _sortedLines) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_sortedLines.default, {
    amount: (0, _model.attr)('money'),
    currencyCode: (0, _model.attr)(),
    frequency: (0, _model.attr)({
      defaultValue: 'monthly'
    }),
    howMany: (0, _model.attr)('string'),
    isActive: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    name: (0, _model.attr)(),
    nextScheduledDate: (0, _model.attr)('date', {
      defaultValue: moment().format('YYYY-MM-DD')
    }),
    note: (0, _model.attr)(),
    remaining: (0, _model.attr)('string'),
    startDate: (0, _model.attr)('date'),
    journalEntryLines: (0, _model.hasMany)('recurringJournalEntryLine'),
    isFinished: Ember.computed('remaining', 'nextScheduledDate', function () {
      return this.remaining == 0;
    })
  });

  _exports.default = _default;
});