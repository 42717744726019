define("admin/mixins/polling-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      this.set('pollingCount', 0);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      var max = this.pollingMax || 20;
      var interval = this.pollingInterval || 10000;
      var pollAgain = this.pollingCheck(model, controller); // as long as any are still fetching, keep refreshing this route until
      // 1) none are still fetching or
      // 2) the route goes away
      // 3) we're not already waiting for a poll

      if (pollAgain && !this.pollingHandle && this.pollingCount < max) {
        this.set('pollingHandle', Ember.run.later(this, function () {
          _this.set('pollingHandle', null);

          _this.incrementProperty('pollingCount');

          controller.set('pollingCount', _this.pollingCount);
          if (_this.isDestroyed || _this.isDestroying) return;
          controller.set('pollingIsRefreshing', true);

          _this.refreshFunc(controller, model).then(function () {
            Ember.run.scheduleOnce('afterRender', _this, function () {
              controller.set('pollingIsRefreshing', false);
            });
          });
        }, interval));
      }
    },
    refreshFunc: function refreshFunc() {
      return this.refresh();
    }
  });

  _exports.default = _default;
});