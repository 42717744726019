define("admin/templates/partials/-social-logins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyE7CKju",
    "block": "{\"symbols\":[],\"statements\":[[7,\"style\",true],[8],[0,\"\\n  @import url(\\\"https://fonts.googleapis.com/css?family=Roboto+Mono\\\");\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row small-gutters mt-3 mb-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-depressable btn-small btn-facebook w-100\"],[12,\"data-test-id\",\"facebook\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"loginFacebook\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-facebook\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Facebook\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-depressable btn-small btn-google w-100 font-family-roboto\"],[12,\"data-test-id\",\"google\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"loginGoogle\"]],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"class\",\"w-1rem pr-1\"],[10,\"src\",\"/assets/images/brand/google-icon.svg\"],[8],[9],[0,\"\\n      Google\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-depressable btn-small btn-linkedin w-100\"],[12,\"data-test-id\",\"linkedin\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"loginLinkedin\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-linkedin2\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"LinkedIn\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/partials/-social-logins.hbs"
    }
  });

  _exports.default = _default;
});