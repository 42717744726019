define("admin/utils/pluck-query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pluckQueryParam;

  function pluckQueryParam(key) {
    var match = document.location.search.match(new RegExp(".*?".concat(key, "=([^&]*).*")));

    if (match && match.length === 2) {
      var value = match[1];
      var url = document.URL.replace(new RegExp("(\\?|&|)".concat(key, "=").concat(value, "(&|)")), '$1').replace(/\?$/, '');
      history.replaceState({}, document.title, url);
      return value;
    }
  }
});