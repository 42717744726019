define("admin/transforms/money", ["exports", "@ember-data/serializer/transform", "admin/utils/parse-number"], function (_exports, _transform, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return +(0, _parseNumber.default)(serialized).round(2);
    },
    serialize: function serialize(deserialized) {
      return (0, _parseNumber.default)(deserialized).round(2) + '';
    }
  });

  _exports.default = _default;
});