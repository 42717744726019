define("admin/components/needs-privilege", ["exports", "admin/mixins/paid-modal-handler"], function (_exports, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Needs = Ember.Component.extend(_paidModalHandler.default, {
    session: Ember.inject.service(),
    tagName: '',
    upgrade: Ember.computed.oneWay('needs'),
    oneBlock: false,
    privileged: Ember.computed('needs', 'session.privileges.@each', function () {
      var _this = this;

      var needs = this.needs;

      if (Ember.typeOf(needs) === 'array') {
        return needs.any(function (n) {
          return _this.session.hasPrivilege(n);
        });
      } else {
        return this.session.hasPrivilege(needs);
      }
    })
  });
  Needs.reopenClass({
    positionalParams: ['needs']
  });
  var _default = Needs;
  _exports.default = _default;
});