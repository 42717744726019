define("admin/models/cloud-file", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    token: (0, _model.attr)(),
    isUploaded: (0, _model.attr)('boolean'),
    isPrivate: (0, _model.attr)('boolean'),
    contentType: (0, _model.attr)(),
    category: (0, _model.attr)(),
    filename: (0, _model.attr)(),
    size: (0, _model.attr)('string'),
    downloadUrl: (0, _model.attr)('downloadUrl'),
    width: (0, _model.attr)(),
    height: (0, _model.attr)(),
    cloudFileable: (0, _model.belongsTo)('cloudFileable', {
      polymorphic: true
    }),
    // Get upload url
    client: Ember.inject.service(),
    fetchUploadUrl: function fetchUploadUrl() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.client.send('GET', 'cloud-files', this.token + '/upload-url', null, opts);
    },
    isImage: Ember.computed('contentType', function () {
      return this.contentType.indexOf('image/') === 0;
    })
  });

  _exports.default = _default;
});