define("admin/components/status-button", ["exports", "admin/utils/later"], function (_exports, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentTitle: '',
    type: 'button',
    _previousState: null,
    fakeSave: false,
    // pretends to save successfully if there are no changes
    handleClick: function handleClick() {
      var _this = this;

      if (this.action) {
        var promise = this.action(); // this is new behavior. If `model` and `state` are not set, then you can also return a promise from the action
        // which will also dicate how the loader appears

        if (promise && promise.then && !this.model && !this.state) {
          this.set('state', 'saving');
          promise.then(function () {
            _this.set('state', 'loaded');

            (0, _later.default)(1000, function () {
              if (!_this.isDestroyed && !_this.isDestroying) {
                _this.set('state', null);
              }
            });
          }, function () {
            _this.set('state', 'invalid');

            (0, _later.default)(1000, function () {
              if (!_this.isDestroyed && !_this.isDestroying) {
                _this.set('state', null);
              }
            });
          });
        }
      }
    },
    actions: {
      click: function click(e) {
        var _this2 = this;

        e.stopPropagation();
        e.preventDefault(); // if they clicked the button but nothing needs to be saved, just pretend
        // like it saved successfully

        if (this.fakeSave && this.model && !this.get('model.hasDirtyAttributes')) {
          this.set('state', 'saving');
          (0, _later.default)(1000, function () {
            if (!_this2.isDestroyed && !_this2.isDestroying) {
              _this2.set('state', 'loaded');
            }
          });
        } // dont let them click it if its already saving


        if (this.isSaving || this.justSaved) {
          return false;
        } // ignore double clicks


        Ember.run.throttle(this, this.handleClick, 1000);
      }
    },
    _stateChanged: Ember.on('init', Ember.observer('currentState', 'title', function () {
      var _this3 = this;

      var previousState = this.get('_previousState');
      var state = this.currentState; // handle case where saving failed for some reason unrelated to validation

      if (state && state.indexOf('uncommitted') > -1 && previousState === 'saving') {
        state = 'invalid';
      }

      switch (state) {
        case 'saving':
          this.set('currentTitle', this.savingTitle || 'Saving…');
          break;

        case 'loaded':
          this.setDefaultTitle();

          if (previousState === 'saving') {
            this.set('justSaved', true);
            this.set('currentTitle', this.savedTitle || 'Saved!');
            (0, _later.default)(1000, function () {
              if (!_this3.isDestroyed && !_this3.isDestroying) {
                _this3.set('justSaved', false);

                _this3.setDefaultTitle();
              }
            });
          }

          break;

        case 'disabled':
          break;

        case 'invalid':
          this.setDefaultTitle();

          if (previousState === 'saving') {
            this.set('justFailed', true);
            (0, _later.default)(5000, function () {
              if (!_this3.isDestroyed && !_this3.isDestroying) {
                _this3.set('justFailed', false);
              }
            });
          }

          break;

        default:
          this.setDefaultTitle();
      }

      this.set('_previousState', state);
    })),
    isSaving: Ember.computed.equal('currentState', 'saving'),
    // saving, disabled, loaded, invalid
    currentState: Ember.computed('modelState', 'state', function () {
      return this.state || this.modelState;
    }),
    modelState: Ember.computed('model.currentState.stateName', 'model.isReloading', function () {
      // return 500: "root.loaded.created.uncommitted"
      var model = this.model;

      if (!model) {
        return null;
      }

      if (model.get('isSaving')) {
        return 'saving';
      } else if (model.get('isLoading') || model.get('isReloading')) {
        return 'disabled';
      } else if (model.get('isLoaded') && model.get('isValid') && model.get('currentState.stateName').indexOf('uncommitted') === -1) {
        return 'loaded';
      } else if (!model.get('isValid')) {
        return 'invalid';
      } else {
        return model.get('currentState.stateName');
      }
    }),
    setDefaultTitle: function setDefaultTitle() {
      this.set('currentTitle', this.title);
    },
    widthStyle: Ember.computed('width', function () {
      return Ember.String.htmlSafe("width: ".concat(this.width));
    })
  });

  _exports.default = _default;
});