define("admin/controllers/login", ["exports", "admin/mixins/social-login-controller"], function (_exports, _socialLoginController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_socialLoginController.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    errors: null,
    accountOptions: null,
    stealth: false,
    actions: {
      login: function login() {
        var _this = this;

        var credentials = this.model.getProperties('email', 'password', 'code');
        this.set('buttonState', 'saving');
        this.set('errors', null);
        this.client.send('POST', 'auth', 'admin-login', credentials).then(function (resp) {
          _this.set('buttonState', 'loaded');

          _this.session.login(resp.token, !!_this.get('model.remember'));

          _this.transitionToRoute('main.dashboard');
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          _this.set('buttonState', 'invalid');

          if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
            var errors = jqXHR.responseJSON.message;
            Object.keys(errors).forEach(function (key) {
              return errors[key] = errors[key].map(function (e) {
                return {
                  message: e
                };
              });
            });

            _this.set('errors', errors);
          } else if (jqXHR.responseJSON && jqXHR.status === 404) {
            _this.overlay.showBanner('error', "That doesn't look quite right!", {
              title: 'Oops!'
            });
          } else {
            _this.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
              title: 'Oops!'
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});