define("admin/utils/sanitize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitize;

  function sanitize(string) {
    return $('<textarea />').html(string).text().replace(/<(?:.|\n)*?>/gm, '');
  }
});