define("admin/mixins/drawers", ["exports", "admin/utils/later", "admin/utils/keycodes"], function (_exports, _later, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    drawer: null,
    drawerParams: Ember.computed('drawer', function () {
      return (this.drawer || '').split('~').map(function (kvp) {
        var kv = kvp.split('.');
        return {
          key: kv[0],
          value: kv[1]
        };
      });
    }),
    // here we allow a brief delay to break apart opening drawers of the same kind with different models.
    // This closes closes the current drawer before opening a new one with the new drawerParams
    // it is too easy to let model attributes leak from one drawer state to another, so to be safe, we have to
    // destroy and open a new drawer when switching models.
    _updateDrawerComponentName: Ember.on('init', Ember.observer('drawerParams.@each', function () {
      var _this = this;

      var first = this.get('drawerParams.firstObject');

      if (first) {
        var name = 'drawers/' + first.key + '-form';
        this.set('drawerComponentName', null);
        Ember.run.next(function () {
          _this.set('drawerComponentName', name);
        });
      }
    })),
    actions: {
      closeDrawer: function closeDrawer(delay, fn) {
        var _this2 = this;

        (0, _later.default)(delay, function () {
          if (!_this2.isDestroyed && !_this2.isDestroying) {
            _this2.set('drawer', null);
          }

          fn && fn();
        });
      }
    },
    // auto close drawer
    _drawerChanged: Ember.on('init', Ember.observer('drawer', function () {
      var _this3 = this;

      if (this.drawer) {
        $(document).on('keydown.esc-close-drawer', function (e) {
          if (e.which == _keycodes.default.ESCAPE) {
            _this3.send('closeDrawer', 0);
          }
        });
      } else {
        $(document).off('.esc-close-drawer');
      }
    }))
  });

  _exports.default = _default;
});