define("admin/routes/main/super/devops/caches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    client: Ember.inject.service(),
    model: function model(_params) {
      return this.client.cached('GET', "admin/devops/caches");
    }
  });

  _exports.default = _default;
});