define("admin/routes/application", ["exports", "admin/utils/redirect"], function (_exports, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.session.isLoggedIn) {
        (0, _redirect.default)('/login');
      }
    }
  });

  _exports.default = _default;
});