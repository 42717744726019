define("admin/transforms/decimal-2", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var value = (serialized * 1).toString();
      var decimals = value.split('.')[1];

      if (!decimals || decimals.length < 2) {
        value = (+value).toFixed(2);
      }

      return value;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});