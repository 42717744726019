define("admin/models/payment", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amount: (0, _model.attr)('money'),
    date: (0, _model.attr)('datetime', {
      defaultValue: moment().format('YYYY-MM-DD')
    }),
    notes: (0, _model.attr)(),
    payerId: (0, _model.attr)(),
    paymentId: (0, _model.attr)(),
    paymentMethod: (0, _model.attr)(),
    referenceNumber: (0, _model.attr)(),
    sendReceipt: (0, _model.attr)('boolean'),
    token: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account'),
    invoice: (0, _model.belongsTo)('invoice'),
    contact: (0, _model.belongsTo)('contact'),
    debitChartAccount: (0, _model.belongsTo)('chartAccount')
  });

  _exports.default = _default;
});