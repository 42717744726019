define("admin/helpers/status-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusColor = statusColor;
  _exports.default = void 0;

  function statusColor(params
  /*, hash*/
  ) {
    switch (params[0]) {
      case 'failure':
        return 'red';

      case 'success':
        return 'green';

      default:
        return 'gold';
    }
  }

  var _default = Ember.Helper.helper(statusColor);

  _exports.default = _default;
});