define("admin/components/suggest/item-select", ["exports", "admin/components/auto-suggest", "admin/utils/later"], function (_exports, _autoSuggest, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Item',
    allowArbitrary: true,
    displayArbitrary: true,
    autoSelectFirst: false,
    layoutName: 'components/auto-suggest',
    throttle: 0,
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.session.hasPrivilege('items')) {
        this.store.cachedQuery('item', {
          filter: {
            kind: this.kind
          }
        });
      }
    },
    focusIn: function focusIn() {
      this.set('fresh', true);
      var hasText = this.get('searchText.length') > 0;

      if (!hasText) {
        this.set('show', true);
      }

      this.updateResultsMatching();
    },
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this = this;

      if (this.session.hasPrivilege('items')) {
        var params = {
          filter: {
            q: search,
            kind: this.kind,
            archived: false
          }
        };
        return this.store.cachedQuery('item', params).then(function (results) {
          if (!_this.isDestroyed && !_this.isDestroying) {
            _this.set('isFetched', true);
          }

          return results;
        });
      } else {
        this.set('isFetched', true);
        return [];
      }
    },
    labelForResult: function labelForResult(result) {
      if (result.get) {
        return result.get('name');
      } else {
        return result;
      }
    },

    /*
    handleAddNew(label) {
      let item = this.store.createRecord('item', {
        name: label,
      })
      this.overlay.showDrawer('drawers/item-form', item, {
        saved: model => {
          this.trySelect(model)
        },
      })
    },
    */
    // Override key functions
    upArrowPressed: function upArrowPressed() {
      if (this.isSuggestShowing) {
        this._super.apply(this, arguments);
      } else {
        return true;
      }
    },
    downArrowPressed: function downArrowPressed() {
      if (this.isSuggestShowing) {
        this._super.apply(this, arguments);
      } else {
        return true;
      }
    },
    enterKeyPressed: function enterKeyPressed() {
      // enter key only does something special if suggest is showing
      // and one of them is selected (like they arrowed down)
      if (this.isSuggestShowing && this.selection) {
        this._super.apply(this, arguments);
      } else {
        return true;
      }
    },
    // dont do anything special on backspace, just lett'r go
    backspaceKeyPressed: function backspaceKeyPressed() {
      var _this2 = this;

      this.set('fresh', false);
      (0, _later.default)(100, function () {
        _this2.set('show', true);
      });
      return true;
    }
  });

  _exports.default = _default;
});