define("admin/transforms/color", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return '#' + serialized.replace(/[^a-zA-Z0-9]/, '');
    },
    serialize: function serialize(deserialized) {
      return deserialized.replace(/[^a-zA-Z0-9]/, '');
    }
  });

  _exports.default = _default;
});