define("admin/templates/main/super/devops/redis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dL2YicCt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"ZipBooks Redis\"],[9],[0,\"\\n\\n  \"],[7,\"h5\",true],[8],[0,\"Search keys\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Here you can enter a pattern to search for keys in Redis. Use with care, if you use a\\n  wildcard (*) when there are a ton of keys, it could be slow. We shouldn't have nearly enough\\nkeys for it to be a problem, just a heads up.\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[5,\"input\",[],[[\"@value\",\"@placeholder\"],[[23,0,[\"pattern\"]],\"some:key\"]]],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-blue btn-inline mt-2\"],[3,\"action\",[[23,0,[]],\"searchRedisKeys\"]],[8],[0,\"GET\"],[9],[0,\"\\n\\n  \"],[7,\"pre\",true],[10,\"class\",\"mt-5\"],[8],[7,\"code\",true],[8],[1,[23,0,[\"keysOutput\"]],false],[9],[9],[0,\"\\n  \"],[7,\"h5\",true],[8],[0,\"Get key\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Here you can enter a key and get the value printed below.\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[5,\"input\",[],[[\"@value\",\"@placeholder\"],[[23,0,[\"key\"]],\"some:key\"]]],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-blue btn-inline mt-2\"],[3,\"action\",[[23,0,[]],\"getRedisKey\"]],[8],[0,\"GET\"],[9],[0,\"\\n\\n  \"],[7,\"pre\",true],[10,\"class\",\"mt-5\"],[8],[7,\"code\",true],[8],[1,[23,0,[\"output\"]],false],[9],[9],[0,\"\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/main/super/devops/redis.hbs"
    }
  });

  _exports.default = _default;
});