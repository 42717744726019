define("admin/serializers/bill-item", ["exports", "admin/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_patchSerializer.default, _embeddedJsonapiRecords.default, {});

  _exports.default = _default;
});