define("admin/components/image-upload", ["exports", "admin/components/file-upload"], function (_exports, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileUpload.default.extend({
    maxDimension: 1500,
    prepareFiles: function prepareFiles(files, done) {
      var _this = this;

      var file = files[0];

      if (file) {
        var reader = new FileReader();
        this.set('reader', reader);
        reader.addEventListener('load', function () {
          var img = new Image();

          img.onload = function () {
            Ember.run(function () {
              if (_this.canUploadFile(file) && _this.canUploadImage(img)) {
                if (_this.get('cloudFile.id')) {
                  _this.send('clear', _this.cloudFile);
                }

                done(files);
              }
            });
          };

          img.src = reader.result; // is the data URL because called with readAsDataURL
        }, false);
        reader.readAsDataURL(file);
      }
    },
    didFinishUploading: function didFinishUploading(cloudFiles) {
      this._super.apply(this, arguments);

      this.set('cloudFile', cloudFiles.get('firstObject'));
      this.set('cloudFile.downloadUrl', this.reader.result);
    },
    canUploadImage: function canUploadImage(img) {
      if (img.width > this.maxDimension || img.height > this.maxDimension) {
        this.overlay.showBanner('error', "Image must be smaller than ".concat(this.maxDimension, "x").concat(this.maxDimension));
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});