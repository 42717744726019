define("admin/serializers/account", ["exports", "admin/serializers/application", "admin/mixins/patch-serializer"], function (_exports, _application, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_patchSerializer.default, {
    alwaysSend: ['values'],
    attrs: {
      logoCloudFile: {
        serialize: false
      },
      subscriptions: {
        serialize: 'records'
      }
    }
  });

  _exports.default = _default;
});