define("admin/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subtract = subtract;
  _exports.default = void 0;

  function subtract(params
  /*, hash*/
  ) {
    return params[0] - params[1];
  }

  var _default = Ember.Helper.helper(subtract);

  _exports.default = _default;
});