define("admin/components/reports/income-statement-line", ["exports", "admin/mixins/testable", "admin/mixins/paid-modal-handler", "admin/utils/tag-serialization"], function (_exports, _testable, _paidModalHandler, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, _testable.default, {
    session: Ember.inject.service(),
    quickLinkExclude: ['Gross profit', 'Operating income', 'Net income'],
    quickLinkSales: ['Sales', 'Other revenue'],
    quickLinkExpenses: ['Expenses', 'Other expenses'],
    actions: {
      viewTransactions: function viewTransactions(chartAccountId) {
        var startDate = moment(this.startDate);
        var endDate = moment(this.endDate);
        var tags = this.tags;

        if (this.tagGroupSelected) {
          tags = tags.filter(function (tag) {
            return tag.kind !== 'kind';
          });
          tags.push(this.get('group.tag'));
        }

        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              chart_account_id: chartAccountId,
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              tags: _tagSerialization.default.serialize(tags),
              confirmed: this.include === 'all' ? '' : 'confirmed'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});