define("admin/helpers/report-period-col-bootstrap-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportPeriodColBootstrapClass = reportPeriodColBootstrapClass;
  _exports.default = void 0;

  function reportPeriodColBootstrapClass(params
  /*, hash*/
  ) {
    var numPeriodCols = params[0];

    if (numPeriodCols === 1) {
      return 'col-sm-12';
    }

    if (numPeriodCols === 2) {
      return 'col-sm-6';
    }

    if (numPeriodCols === 3) {
      return 'col-sm-4';
    }

    return 'col-12 col-sm-3';
  }

  var _default = Ember.Helper.helper(reportPeriodColBootstrapClass);

  _exports.default = _default;
});