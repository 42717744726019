define("admin/helpers/format-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTime = formatTime;
  _exports.default = void 0;

  function formatTime(params, hash) {
    var duration = moment.duration(params[0] || 0, hash && hash.unit || 'seconds');
    return "".concat(duration.hours().pad(2), ":").concat(duration.minutes().pad(2), ":").concat(duration.seconds().pad(2));
  }

  var _default = Ember.Helper.helper(formatTime);

  _exports.default = _default;
});