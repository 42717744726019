define("admin/services/accountant-client", ["exports", "admin/services/client"], function (_exports, _client) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _client.default.extend({
    accountantSession: Ember.inject.service(),
    headers: function headers(_opts) {
      return {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: "Bearer ".concat(this.get('accountantSession.token'))
      };
    }
  });

  _exports.default = _default;
});