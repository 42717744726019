define("admin/helpers/redness", ["exports", "admin/utils/amount-sign"], function (_exports, _amountSign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redness = redness;
  _exports.default = void 0;

  // is a decrease in net worth (will usually be used to make amount appear red)
  function redness(params, hash) {
    if (hash.isTransfer && !hash.isFiltered) {
      return 'blue';
    }

    var journalEntryLine = params[0];
    var chartAccount = journalEntryLine.get('chartAccount');
    var info = chartAccount.get('classificationInfo');
    var kind = journalEntryLine.get('kind');

    if (!info || !kind) {
      return '';
    }

    var sign = (0, _amountSign.default)(info.creditRed, kind); // if this line is owners equity and the other side is one of oeSwap, flip the redness

    if (['Equity', 'Retained earnings', 'Invested capital'].includes(chartAccount.get('classification')) && hash.exception) {
      var other = journalEntryLine.get('otherLines.firstObject.chartAccount.classificationInfo');

      if (other && other.oeSwap) {
        sign *= -1;
      }
    }

    return sign < 0 ? 'red' : 'green';
  }

  var _default = Ember.Helper.helper(redness);

  _exports.default = _default;
});