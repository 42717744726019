define("admin/utils/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentIncrease = percentIncrease;

  function percentIncrease(previous, current) {
    var increase = current - previous;
    var percent = Math.round(increase / previous * 100);

    if (percent === Infinity) {
      return 100;
    } else {
      return percent;
    }
  }
});