define("admin/overrides/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.reopen({
    autoAddTestId: true,
    stillExists: Ember.computed(function () {
      return !this.isDestroying && !this.isDestroyed;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.inClass) {
        var element = this.inSelector ? this.$(this.inSelector) : this.$();
        element.addClass(this.inClass);
      }

      if (Ember.testing && this.tagName !== '' && (this.$('[data-test-id]').length === 0 || this.noTestIdPassthrough)) {
        if (this.autoAddTestId) {
          this.$().attr('data-test-id', this.testId || this.componentName);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.outClass) {
        var element = this.$();
        var clone = element.clone().addClass('clone'); // apply outClass

        var inElement = this.inSelector ? clone.find(this.inSelector) : clone;
        var outElement = this.outSelector ? clone.find(this.outSelector) : clone;
        inElement.removeClass(this.inClass);
        outElement.addClass(this.outClass);
        clone.on('animationend MSAnimationEnd webkitAnimationEnd', function (e) {
          if (e.originalEvent.target === outElement[0]) {
            clone.remove();
          }
        });

        if (this.outAttachment === 'prepend') {
          clone.prependTo(element.parent());
        } else {
          clone.appendTo(element.parent());
        }
      }

      this._super.apply(this, arguments);
    },

    get componentName() {
      return this.toString().match(/([a-z0-9-]+?)::/)[1];
    }

  });

  _exports.default = _default;
});