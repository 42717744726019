define("admin/models/affini-pay-integration", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    chargeAccount: (0, _model.attr)(),
    chargeAccounts: (0, _model.attr)(),
    currency: (0, _model.attr)(),
    kind: (0, _model.attr)()
  });

  _exports.default = _default;
});