define("admin/services/url-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _cache: {},
    expires: 30 * 1000,
    // 30 seconds
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_cache', {});
    },
    read: function read(url, params) {
      var timestamp = new Date().getTime();
      var key = this.key(url, params);

      if (this._cache[key]) {
        var cached = this._cache[key];

        if (cached.expireAt > timestamp) {
          return cached.value;
        } else {
          this.clear(url, params);
        }
      }

      return null;
    },
    write: function write(url, params, value, expires) {
      var _this = this;

      expires = expires || this.expires;
      var key = this.key(url, params);
      var timestamp = new Date().getTime();
      this._cache[key] = {
        expireAt: timestamp + expires,
        value: value
      };

      if (value.catch) {
        value.catch(function (_reason) {
          return _this.clear(url, params);
        });
      }
    },
    clear: function clear(url, params) {
      if (url) {
        var key = this.key(url, params);

        if (this._cache[key]) {
          delete this._cache[key];
        }
      } else {
        this._cache = {};
      }
    },
    key: function key(url, params) {
      return "".concat(url, "?").concat($.param(params || {}));
    }
  });

  _exports.default = _default;
});