define("admin/routes/main/super/admin-users", ["exports", "admin/mixins/refreshable-route", "admin/mixins/infinite-scroll"], function (_exports, _refreshableRoute, _infiniteScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, _infiniteScroll.default, {
    graphql: Ember.inject.service(),
    model: function model(_params) {
      return this.graphql.send("\n      query {\n        admins(first: 100) {\n          edges {\n            node {\n              identity {\n                email\n              }\n              support\n              super\n            }\n          }\n        }\n      }\n      ");
    }
  });

  _exports.default = _default;
});