define("admin/models/credit-card", ["exports", "@ember-data/model", "admin/models/model", "zipbooks/utils/credit-cards"], function (_exports, _model, _model2, _creditCards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    brand: (0, _model.attr)(),
    expMonth: (0, _model.attr)('string', {
      example: '7'
    }),
    expYear: (0, _model.attr)('string', {
      example: '2019'
    }),
    last4: (0, _model.attr)('string', {
      example: '4242'
    }),
    addressLine1: (0, _model.attr)(),
    addressLine2: (0, _model.attr)(),
    addressCity: (0, _model.attr)(),
    addressState: (0, _model.attr)(),
    addressZip: (0, _model.attr)('string', {
      example: '84014'
    }),
    token: (0, _model.attr)(),
    shortBrand: Ember.computed('brand', function () {
      switch (this.brand) {
        case 'Visa':
          return 'visa';

        case 'American Express':
          return 'amex';

        case 'Discover':
          return 'discover';

        case 'MasterCard':
          return 'mastercard';

        default:
          return null;
      }
    }),
    brandImageUrl: Ember.computed('shortBrand', function () {
      return (0, _creditCards.default)(this.shortBrand, 'nobg');
    })
  });

  _exports.default = _default;
});