define("admin/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    hash = $.extend({}, {
      parseFormat: 'YYYY-MM-DD',
      now: moment()
    }, hash);
    var now = hash.now;

    if (!params[0]) {
      return 'never';
    }

    var momentDate;

    if (params[0] === 'All Time') {
      momentDate = now;
    } else if (params[0] instanceof Date) {
      momentDate = moment(params[0]);
    } else if (params[0] instanceof moment) {
      momentDate = params[0];
    } else {
      momentDate = moment(params[0], hash.parseFormat);
    }

    var outputFormat;

    if (params[1]) {
      outputFormat = params[1];
    } else if (momentDate.year() < now.year()) {
      outputFormat = 'MMM D, YYYY';
    } else {
      outputFormat = 'MMM D';
    }

    return momentDate.format(outputFormat);
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});