define("admin/helpers/symbol-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.symbolSafe = symbolSafe;
  _exports.default = void 0;

  function symbolSafe(params
  /*, hash*/
  ) {
    if (['MUR', 'LKR'].indexOf(params[0]) > -1) {
      return 'symbol-safe';
    }

    return '';
  }

  var _default = Ember.Helper.helper(symbolSafe);

  _exports.default = _default;
});