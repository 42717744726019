define("admin/components/file-upload", ["exports", "admin/utils/file-uploader", "admin/utils/file-list-to-array", "admin/mixins/file-droppable", "admin/mixins/testable", "admin/utils/bytes-to-size", "admin/utils/overridable-computed"], function (_exports, _fileUploader, _fileListToArray, _fileDroppable, _testable, _bytesToSize, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileDroppable.default, _testable.default, {
    classNames: ['file-upload'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    cloudFile: null,
    state: 'default',
    maxSize: Math.pow(1024, 2),
    sessionToken: (0, _overridableComputed.default)('sessionToken', function () {
      return this.session.token;
    }),
    actions: {
      filesSelected: function filesSelected(e) {
        var files = this.filesFrom(e);
        this.handleFiles(files);
      },
      filesDropped: function filesDropped(files) {
        this.handleFiles(files);
      },
      clear: function clear(cloudFile) {
        if (cloudFile.get('token')) {
          this.client.send('DELETE', 'cloud-files', cloudFile.get('token'), {
            token: this.sessionToken
          });
        }

        if (this.clear) {
          this.clear(cloudFile);
        }

        cloudFile.set('downloadUrl', null);
        cloudFile.set('isUploaded', false);
        this.set('uploader', null);
      }
    },

    /**************
     * Overridable
     ***************/
    handleFiles: function handleFiles(files) {
      var _this = this;

      // have to convert FileList to an array we can call 'map' on
      var filesArray = (0, _fileListToArray.default)(files); // prepare files

      this.prepareFiles(filesArray, function (preparedFiles) {
        _this.uploadFiles(preparedFiles);
      });
    },
    prepareFiles: function prepareFiles(files, done) {
      done(files);
    },
    filesFrom: function filesFrom(e) {
      return e.testingFiles || e.target.files;
    },
    canUploadFile: function canUploadFile(file) {
      if (file.size > this.maxSize && !this.session.isStealth) {
        this.overlay.showBanner('error', 'The maximum allowed file size is ' + (0, _bytesToSize.default)(this.maxSize) + '.');
        return false;
      }

      return true;
    },
    uploaderForFile: function uploaderForFile(file) {
      var cloudFile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var attrs = {
        contentType: Ember.isPresent(file.type) ? file.type : 'application/octet-stream',
        filename: file.name,
        size: file.size,
        category: this.getWithDefault('category', null),
        isUploaded: true,
        isPrivate: this.getWithDefault('isPrivate', false)
      };

      if (!cloudFile) {
        cloudFile = this.store.createRecord('cloudFile', attrs);
      } else {
        cloudFile.setProperties(attrs);
      }

      return Ember.RSVP.resolve(_fileUploader.default.create({
        cloudFile: cloudFile,
        file: file,
        state: this.canUploadFile(file) ? 'waiting' : 'invalid'
      }));
    },
    setUploaders: function setUploaders(uploaders) {
      this.set('uploader', uploaders[0]);
    },
    didFinishUploading: function didFinishUploading(cloudFiles) {
      var _this2 = this;

      // reload all the cloud files so we have the downloadUrl
      cloudFiles.forEach(function (cf) {
        return cf.reload({
          token: _this2.sessionToken
        });
      }); // set them all as isUploaded

      cloudFiles.forEach(function (cf) {
        return cf.set('isUploaded', true);
      });
    },
    uploadFiles: function uploadFiles(files) {
      var _this3 = this;

      if (Ember.isEmpty(files)) {
        return;
      }

      var store = this.store; // create an uploader from each file

      var uploaderPromises; // if we're given a cloudFile, we upload the first file into it

      if (this.get('cloudFile.token')) {
        var file = files[0]; // not sure why but referencing the cloud file here isn't a "real" instance

        var cloudFile = store.peekRecord('cloudFile', this.get('cloudFile.id'));
        var uploader = this.uploaderForFile(file, cloudFile);
        uploaderPromises = [uploader];
      } else {
        // for each file, create a cloudFile record and an uploader
        uploaderPromises = files.map(function (file) {
          return _this3.uploaderForFile(file);
        });
      }

      return Ember.RSVP.all(uploaderPromises).then(function (uploaders) {
        // give the template the uploaders so they can display all the info about the file/upload progress
        _this3.setUploaders(uploaders); // we dont call upload on the uploaders that fail validation


        uploaders = uploaders.filter(function (u) {
          return u.get('state') !== 'invalid';
        }); // start the uploaders and collect the promises

        var promises = uploaders.map(function (uploader) {
          return uploader.upload({
            token: _this3.sessionToken
          });
        }); // when everything is done uploading, send the didUpload action with all
        // the cloudfiles that were uploaded

        return Ember.RSVP.all(promises).then(function (results) {
          _this3.didFinishUploading(results);

          Ember.tryInvoke(_this3, 'didUpload', [results]);
        });
      });
    }
  });

  _exports.default = _default;
});