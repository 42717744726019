define("admin/models/external-location", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    address_1: (0, _model.attr)(),
    address_2: (0, _model.attr)(),
    city: (0, _model.attr)(),
    country: (0, _model.attr)(),
    name: (0, _model.attr)(),
    phone: (0, _model.attr)(),
    placeId: (0, _model.attr)(),
    postalCode: (0, _model.attr)(),
    state: (0, _model.attr)(),
    website: (0, _model.attr)(),
    description: (0, _model.attr)(),
    payload: (0, _model.attr)(),
    media: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account'),
    location: (0, _model.belongsTo)('location'),
    integration: (0, _model.belongsTo)('locatable', {
      polymorphic: true
    }),
    integrationObject: (0, _model.belongsTo)('integrationObject'),
    appointmentUrl: Ember.computed('payload.attributes.@each', function () {
      var found = this.getWithDefault('payload.attributes', []).find(function (a) {
        return a.attributeId === 'url_appointment';
      });

      if (found) {
        return found.urlValues.firstObject.url;
      }
    }),
    images: Ember.computed('media.mediaItems.@each', function () {
      return this.getWithDefault('media.mediaItems', []).map(function (i) {
        return {
          url: i.googleUrl,
          thumbnailUrl: i.thumbnailUrl
        };
      });
    }),
    externalEditLink: Ember.computed('integrationObject.externalId', function () {
      var id = this.get('integrationObject.externalId');

      switch (this.get('integration.modelType')) {
        case 'google-my-business-integration':
          id = id.substr(id.lastIndexOf('/') + 1);
          return "https://business.google.com/edit/l/u".concat(id);

        case 'facebook-business-integration':
          return "https://www.facebook.com/".concat(id);

        case 'square-integration':
          return "https://squareup.com/dashboard/locations/".concat(id);

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});