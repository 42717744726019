define("admin/components/overlays/notification-banner", ["exports", "admin/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    overlay: Ember.inject.service(),
    inClass: 'bannerSlideInDown',
    inSelector: '.notification',
    outClass: 'bannerSlideOutUp',
    outSelector: '.notification',
    title: Ember.computed('model.style', function () {
      if (this.get('model.title')) {
        return this.get('model.title');
      } else {
        switch (this.get('model.style')) {
          case 'error':
            return 'Error';

          case 'success':
            return 'Success!';
        }
      }
    }),
    click: function click() {
      this.overlay.close(this.ref);
    }
  });

  _exports.default = _default;
});