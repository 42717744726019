define("admin/routes/main/super/statements", ["exports", "admin/mixins/refreshable-route", "admin/mixins/infinite-scroll", "admin/utils/sort-param"], function (_exports, _refreshableRoute, _infiniteScroll, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, _infiniteScroll.default, {
    client: Ember.inject.service(),
    queryParams: {
      sort: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      },
      statementMonth: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var queryParams = {
        page: {
          page: params.page || 1,
          'page-size': 100
        },
        sort: (0, _sortParam.default)(params),
        filter: {
          month: params.statementMonth
        }
      };

      if (params.searchText) {
        queryParams.filter.name = params.searchText;
      }

      this.set('params', queryParams);
      return this.store.cachedQuery('account-statement', queryParams).then(function (results) {
        _this.set('nextPage', results.meta.links.next);

        return {
          statements: results.toArray(),
          lastUpdated: results.meta['last-updated-at']
        };
      });
    },
    infiniteLoadMore: function infiniteLoadMore() {
      var _this2 = this;

      if (this.nextPage) {
        var params = this.params;
        params.page.page++;
        this.set('params', params);
        return this.store.cachedQuery('account-statement', params).then(function (results) {
          _this2.set('nextPage', results.meta.links.next);

          _this2.get('controller.model.statements').pushObjects(results.toArray());

          Ember.run.later(_this2, function () {
            _this2.set('loading', false);
          }, 2000);
        });
      } else {
        this.set('finished', true);
        this.set('loading', false);
      }
    }
  });

  _exports.default = _default;
});