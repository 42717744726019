define("admin/utils/amount-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = amountSign;

  function amountSign(creditIsNegative, kind) {
    return creditIsNegative && kind === 'credit' || !creditIsNegative && kind === 'debit' ? -1 : +1;
  }
});