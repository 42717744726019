define("admin/mixins/reports/date-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    errors: Ember.computed('start_date', 'end_date', function () {
      if (moment(this.start_date).isAfter(moment(this.end_date))) {
        return {
          start_date: [{
            message: null
          }],
          end_date: [{
            message: null
          }]
        };
      }
    })
  });

  _exports.default = _default;
});