define("admin/models/item", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/taggable"], function (_exports, _model, _model2, _taggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, {
    description: (0, _model.attr)(),
    group: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    metadata: (0, _model.attr)(),
    name: (0, _model.attr)(),
    price: (0, _model.attr)('money'),
    sku: (0, _model.attr)(),
    total: (0, _model.attr)('money'),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    integrationObject: (0, _model.belongsTo)('integrationObject')
  });

  _exports.default = _default;
});