define("admin/models/account", ["exports", "@ember-data/model", "admin/models/model", "admin/utils/initials", "admin/mixins/recipientable"], function (_exports, _model, _model2, _initials, _recipientable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_recipientable.default, {
    annualRevenue: (0, _model.attr)(),
    businessEntityType: (0, _model.attr)(),
    businessMonthlyAverageBankBalance: (0, _model.attr)(),
    cogsCategories: (0, _model.attr)(),
    cobrandLogoUrl: (0, _model.attr)(),
    defaultBccAddress: (0, _model.attr)(),
    defaultCurrencyCode: (0, _model.attr)('string', {
      defaultValue: 'USD'
    }),
    foundingDate: (0, _model.attr)('date'),
    industry: (0, _model.attr)(),
    integrations: (0, _model.attr)(),
    name: (0, _model.attr)(),
    numberOfEmployees: (0, _model.attr)(),
    paypalEmailAddress: (0, _model.attr)(),
    stripeCardId: (0, _model.attr)(),
    stripeCustomerId: (0, _model.attr)(),
    initials: Ember.computed('name', 'defaultBccAddress', function () {
      return (0, _initials.default)(this.name, {
        fallback: this.defaultBccAddress
      });
    }),

    /******************
     * Client Account Values
     *******************/
    hasDocumentsPrivilege: (0, _model.attr)('boolean'),
    unconfirmedCount: (0, _model.attr)(),
    commentCount: (0, _model.attr)(),
    hasTransactions: (0, _model.attr)('boolean'),

    /******************
     * Relationships
     *******************/
    linkedContact: (0, _model.belongsTo)('contact'),
    logoCloudFile: (0, _model.belongsTo)('cloudFile'),
    settings: (0, _model.belongsTo)('account-settings'),
    inheritedSubscriptions: (0, _model.hasMany)('subscription'),
    locations: (0, _model.hasMany)('location'),
    subscriptions: (0, _model.hasMany)('subscription'),
    users: (0, _model.hasMany)('user', {
      inverse: 'account'
    }),
    primaryLocation: Ember.computed('locations.@each.isPrimary', function () {
      return this.locations.find(function (p) {
        return p.get('isPrimary');
      });
    }),
    userCount: Ember.computed('users.length', function () {
      return this.users.filter(function (u) {
        return u.id;
      }).length;
    })
  });

  _exports.default = _default;
});