define("admin/components/draggable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragging: null,
    start: function start(item) {
      this.set('dragging', item);
    },
    stop: function stop(item) {
      Ember.tryInvoke(this, 'dropped', [item]);
    },
    over: function over(item) {
      var dragging = this.dragging;

      if (dragging) {
        Ember.tryInvoke(this, 'swap', [dragging, item]);
      }
    }
  });

  _exports.default = _default;
});