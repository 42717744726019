define("admin/utils/chartjs-plugins", ["exports", "admin/utils/graph-colors"], function (_exports, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.drawRedBelowZero = drawRedBelowZero;
  _exports.drawTooltipLine = drawTooltipLine;
  _exports.drawRoundedBorder = drawRoundedBorder;
  _exports.drawZeroLine = drawZeroLine;

  function drawRedBelowZero() {
    return {
      beforeDatasetsUpdate: function beforeDatasetsUpdate(chart) {
        // get the min and max values
        var min = Math.min.apply(null, chart.data.datasets[2].data);
        var max = Math.max.apply(null, chart.data.datasets[2].data); // figure out the pixels for these and the value 0

        var top = chart.scales.y.getPixelForValue(max);
        var zero = chart.scales.y.getPixelForValue(0);
        var bottom = chart.scales.y.getPixelForValue(min); // build a gradient that switches color at the 0 point

        if (bottom - top > 0) {
          var ctx = chart.ctx;
          var gradient = ctx.createLinearGradient(0, top, 0, bottom);
          var ratio = Math.min((zero - top) / (bottom - top), 1);
          gradient.addColorStop(0, _graphColors.default.net_income);
          gradient.addColorStop(ratio, _graphColors.default.net_income);
          gradient.addColorStop(ratio, _graphColors.default.loss);
          gradient.addColorStop(1, _graphColors.default.loss);
          chart.data.datasets[2].borderColor = gradient;
          chart.data.datasets[5].borderColor = gradient;
        }
      }
    };
  }

  function drawTooltipLine() {
    return {
      beforeDatasetsDraw: function beforeDatasetsDraw(chart, _options) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          var activePoint = chart.tooltip._active[0],
              ctx = chart.ctx,
              x = activePoint.tooltipPosition().x,
              topY = chart.scales.y.top,
              bottomY = chart.scales.y.bottom; // draw line

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = _graphColors.default.lightGray;
          ctx.stroke();
          ctx.restore();
        }
      }
    };
  }

  function drawRoundedBorder() {
    return {
      afterDatasetsDraw: function afterDatasetsDraw(chart, _options) {
        var ctx = chart.ctx;
        ctx.save();
        ctx.lineWidth = 2;
        ctx.strokeStyle = _graphColors.default.lightGray;
        ctx.translate(0.5, 0.5);
        roundedRect(ctx, chart.scales.x.left, chart.scales.y.top, chart.scales.x.right - chart.scales.x.left, chart.scales.y.bottom - chart.scales.y.top, 3);
        ctx.restore();
      }
    };
  }

  function drawZeroLine() {
    return {
      beforeDatasetsDraw: function beforeDatasetsDraw(chart, _options) {
        var ctx = chart.ctx;
        var zero = Math.round(chart.scales.y.getPixelForValue(0));

        if (zero < chart.scales.y.bottom) {
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(chart.scales.x.left, zero);
          ctx.lineTo(chart.scales.x.right, zero);
          ctx.translate(0, 0.5);
          ctx.lineWidth = 1;
          ctx.strokeStyle = _graphColors.default.zeroLine;
          ctx.stroke();
          ctx.restore();
        }
      }
    };
  }

  function roundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x, y + radius);
    ctx.lineTo(x, y + height - radius);
    ctx.arcTo(x, y + height, x + radius, y + height, radius);
    ctx.lineTo(x + width - radius, y + height);
    ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
    ctx.lineTo(x + width, y + radius);
    ctx.arcTo(x + width, y, x + width - radius, y, radius);
    ctx.lineTo(x + radius, y);
    ctx.arcTo(x, y, x, y + radius, radius);
    ctx.stroke();
  }
});