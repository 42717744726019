define("admin/mixins/device-registration-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    window: Ember.inject.service('window'),
    document: Ember.inject.service('document'),
    session: '',
    org: 'ceurt9zj',
    deviceRegistered: Ember.computed(function () {
      return this.window.sessionStorage.getItem('tmx_deviceregistered') || false;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.registerDevice();
    },
    registerDevice: function registerDevice() {
      var forceRegisterDevice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.deviceRegistered || forceRegisterDevice || this.session.length <= 0) {
        if (!this.checkTmxForPartnerDisable()) {
          // generate 32 character token
          var values = '0123456789abcdefghiklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXTZ';

          for (var i = 0; i < 32; i++) {
            var rand = Math.floor(Math.random() * values.length);
            this.session += values.substring(rand, rand + 1);
          } // register device


          var script = this.document.createElement('script');
          script.setAttribute('nonce', 'BDC_CSP_NONCE');
          script.type = 'text/javascript';
          script.src = "https://tm.bdc-cdn.com/fp/tags.js?org_id=".concat(this.org, "&session_id=").concat(this.session);
          script.async = true;
          script.tabIndex = -1;
          this.document.body.appendChild(script);
          this.window.sessionStorage.setItem('tmx_deviceregistered', 'true');
          this.set('deviceRegistered', true);
        }
      }
    },
    checkTmxForPartnerDisable: function checkTmxForPartnerDisable() {
      // Implement your logic to check ThreatMetrix for partner disable
      return false; // Dummy return value
    }
  });

  _exports.default = _default;
});