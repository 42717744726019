define("admin/components/toggle-switch", ["exports", "admin/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: ['d-inline-block'],
    checked: false,
    click: function click() {
      Ember.tryInvoke(this, 'didClick', [this.checked]);

      if (this.disabled === true) {
        return false;
      }

      var checked = !!this.$().find('.switch-input').prop('checked');
      this.set('checked', !checked);
      Ember.tryInvoke(this, 'change', [this.checked]);
      return false;
    }
  });

  _exports.default = _default;
});