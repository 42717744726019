define("admin/utils/slow-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.slowNativeArray = slowNativeArray;

  function _default(content) {
    var _this = this;

    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var per = opts.per || 10;
    var delay = opts.delay || 100;
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var startLoad = function startLoad(recordArray) {
        var all = recordArray.get('content').toArray();
        recordArray.get('content').clear();
        resolve(recordArray);

        var loadMore = function loadMore(step) {
          var startIndex = per * step;
          var endIndex = startIndex + per;

          if (startIndex < all.length) {
            var next = all.slice(startIndex, endIndex);
            recordArray.get('content').pushObjects(next);
            Ember.run.later(_this, function () {
              return loadMore(step + 1);
            }, delay);
          }
        };

        loadMore(0);
      };

      if (content.then) {
        content.then(function (loaded) {
          return startLoad(loaded);
        }, function (error) {
          return reject(error);
        });
      } else {
        startLoad(content);
      }
    });
  }

  function slowNativeArray(content) {
    var _this2 = this;

    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var per = opts.per || 10;
    var delay = opts.delay || 100; // create a copy

    var all = content.slice(); // clear the exiting array

    content.length = 0;

    var loadMore = function loadMore(step) {
      var startIndex = per * step;
      var endIndex = startIndex + per;

      if (startIndex < all.length) {
        var next = all.slice(startIndex, endIndex);
        content.pushObjects(next);
        Ember.run.later(_this2, function () {
          return loadMore(step + 1);
        }, delay);
      }
    };

    loadMore(0);
    return content;
  }
});