define("admin/mixins/clicked-outside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.outsideClickTriggerElement) {
        this.outsideClickTriggerElement().on('click', function ()
        /*event*/
        {
          // if anchor is click to close rather than open
          if (_this.visible || _this.get('disabled')) {
            return;
          }

          Ember.run(function () {
            _this.didClick();

            _this.setupOutsideClickListener();
          });
        });
      } else {
        this.setupOutsideClickListener();
      }
    },
    setupOutsideClickListener: function setupOutsideClickListener() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        Ember.run.later(this, function () {
          $(window).on('click', $.proxy(_this2.anywhereClick, _this2));
        }, 100);
      });
    },
    anywhereClick: function anywhereClick(windowEvent) {
      var _this3 = this;

      Ember.run(function () {
        if (_this3.isDestroyed || _this3.isDestroying) {
          $(window).off('click', _this3.anywhereClick);
          return;
        }

        if (_this3.checkIfClickWasOutside(windowEvent)) {
          _this3.didClickOutside();

          $(window).off('click', _this3.anywhereClick);
        }
      });
    },
    // CALLBACKS
    // outsideClickTriggerElement() { optional }
    checkIfClickWasOutside: function checkIfClickWasOutside(windowEvent) {
      var me = this.$()[0];
      var withinMe = windowEvent.target === me || me.contains(windowEvent.target);
      var clickedWithinAnchor = $(windowEvent.target).closest('a').length > 0;
      return !withinMe || clickedWithinAnchor;
    },
    didClick: function didClick() {
      (false && Ember.warn('You must implement this', {
        id: 'didClick'
      }));
    },
    didClickOutside: function didClickOutside() {
      (false && Ember.warn('You must implement this', {
        id: 'didClickOutside'
      }));
    }
  });

  _exports.default = _default;
});