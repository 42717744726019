define("admin/templates/components/dashboard/chart-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/4RFLzMm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"chartjs-tooltip-content chartjs-tooltip-content-flexible-width \",[28,\"if\",[[24,[\"titled\"]],\"chartjs-tooltip-content-titled\"],null]]]],[8],[0,\"\\n  \"],[14,1,[[24,[\"data\",\"context\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/dashboard/chart-tooltip.hbs"
    }
  });

  _exports.default = _default;
});