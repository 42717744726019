define("admin/mixins/timestamp-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    attrs: {
      archivedAt: {
        serialize: false
      },
      deletedAt: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});