define("admin/models/tag", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    taggedJournalEntryLine: (0, _model.belongsTo)('journalEntryLine'),
    taggableType: (0, _model.attr)(),
    // the serializer sets this, does not come from server
    taggable: (0, _model.belongsTo)('taggable', {
      polymorphic: true
    }),
    isSystem: (0, _model.attr)()
  });

  _exports.default = _default;
});