define("admin/controllers/main/super/devops/caches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    actions: {
      retryCaches: function retryCaches(event) {
        event.preventDefault();
        var count = event.target.children[0].value;
        return this.client.POST("admin/devops/retry-caches", null, {
          count: count
        });
      }
    }
  });

  _exports.default = _default;
});