define("admin/helpers/initials", ["exports", "admin/utils/initials"], function (_exports, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initials = initials;
  _exports.default = void 0;

  function initials(params, hash) {
    return (0, _initials.default)(params[0], hash);
  }

  var _default = Ember.Helper.helper(initials);

  _exports.default = _default;
});