define("admin/helpers/nl2br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nl2br = nl2br;
  _exports.default = void 0;

  function nl2br(params
  /*, hash*/
  ) {
    if (!params[0]) {
      return;
    }

    return new Ember.String.htmlSafe(params[0].replace(/(<([^>]+)>)/ig, '').replace(/\n/g, '<br>'));
  }

  var _default = Ember.Helper.helper(nl2br);

  _exports.default = _default;
});