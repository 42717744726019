define("admin/templates/partials/-corner-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FXI7m4ya",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"fadeInFast corner-ribbon top-left sticky shadow bg-color-\",[28,\"status-color\",[[24,[\"model\",\"status\"]]],null],\" \",[28,\"if\",[[28,\"gt\",[[24,[\"model\",\"status\",\"length\"]],12],null],\"corner-ribbon-long\",\"\"],null]]]],[10,\"data-test-id\",\"status-ribbon\"],[8],[1,[24,[\"model\",\"status\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/partials/-corner-ribbon.hbs"
    }
  });

  _exports.default = _default;
});