define("admin/router", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('main', {
      path: '/'
    }, function () {
      this.route('dashboard');
      this.route('super', function () {
        this.route('admin-users');
        this.route('statements');
        this.route('suspicious-accounts');
        this.route('devops', function () {
          this.route('jobs');
          this.route('icons');
          this.route('caches');
          this.route('redis');
        });
      });
      this.route('support', function () {
        this.route('admin-login');
        this.route('account-details', {
          path: '/account-details/:account_id'
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});