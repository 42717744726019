define("admin/helpers/current-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentUrl = currentUrl;
  _exports.default = void 0;

  function currentUrl(_params, hash) {
    var uri;

    if (hash.host) {
      uri = window.location.origin;
    } else if (hash.full) {
      uri = window.location.href;
    } else {
      uri = window.location.origin + window.location.pathname;
    }

    if (hash.params) {
      Object.keys(hash.params).forEach(function (k) {
        var v = hash.params[k];
        uri += "".concat(uri.indexOf('?') === -1 ? '?' : '&').concat(k, "=").concat(v);
      });
    }

    if (hash.encode) {
      return encodeURIComponent(uri);
    } else {
      return uri;
    }
  }

  var _default = Ember.Helper.helper(currentUrl);

  _exports.default = _default;
});