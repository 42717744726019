define("admin/models/document", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/notificationable"], function (_exports, _model, _model2, _notificationable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_notificationable.default, {
    title: (0, _model.attr)(),
    filename: (0, _model.attr)(),
    contentType: (0, _model.attr)(),
    date: (0, _model.attr)('date'),
    account: (0, _model.belongsTo)('account'),
    cloudFile: (0, _model.belongsTo)('cloudFile')
  });

  _exports.default = _default;
});