define("admin/models/square-integration", ["exports", "@ember-data/model", "admin/models/model", "zipbooks/mixins/locatable"], function (_exports, _model, _model2, _locatable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_locatable.default, {
    enableSync: (0, _model.attr)(),
    syncedTo: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    oauthProvider: (0, _model.attr)(),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    feeChartAccount: (0, _model.belongsTo)('chartAccount'),
    saleChartAccount: (0, _model.belongsTo)('chartAccount'),
    bankAccount: (0, _model.belongsTo)('bankAccount'),
    providerInfo: Ember.computed('oauthProvider', function () {
      return {
        fullName: 'Square',
        shortName: 'Square',
        locationsName: 'locations',
        syncPrompt: "We'll connect these locations but won't change your business info on Square.",
        key: 'square',
        icon: '/assets/images/brand/square-icon.svg',
        allowManyExternalToOneInternal: true,
        archiveDontManage: true
      };
    })
  });

  _exports.default = _default;
});