define("admin/mixins/actorable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: (0, _model.hasMany)('action')
  });

  _exports.default = _default;
});