define("admin/controllers/main/support/admin-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    queryParams: ['searchText', 'searchField', 'accountId'],
    searchField: 'account_id',
    actions: {
      updateSearch: function updateSearch(_search) {
        this.send('refreshRoute');
      },
      generateCode: function generateCode(userId) {
        var _this = this;

        this.client.send('POST', 'admin', 'support/generate-login-token', {
          user_id: userId
        }).then(function (resp) {
          navigator.permissions.query({
            name: "clipboard-write"
          }).then(function (result) {
            if (result.state == "granted" || result.state == "prompt") {
              navigator.clipboard.writeText(resp.code).then(function () {
                _this.overlay.showBanner('success', "Copied ".concat(resp.code, " to your clipboard!"));
              }, function () {
                _this.overlay.showBanner('error', "Failed to copy ".concat(resp.code, " to clipboard"), {
                  title: 'Oops!',
                  delay: 10000
                });
              });
            }
          });
        });
      },
      copyMXGuid: function copyMXGuid(user) {
        var _this2 = this;

        navigator.permissions.query({
          name: "clipboard-write"
        }).then(function (result) {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard.writeText(user.mx_guid).then(function () {
              _this2.overlay.showBanner('success', "Copied ".concat(user.mx_guid, " to your clipboard!"));
            }, function () {
              _this2.overlay.showBanner('error', "Failed to copy ".concat(user.mx_guid, " to clipboard"), {
                title: 'Oops!',
                delay: 10000
              });
            });
          }
        });
      },
      unsuspend: function unsuspend(accountId) {
        var _this3 = this;

        return this.client.send('PATCH', "admin/unsuspend/".concat(accountId)).then(function () {
          return _this3.send('refreshRoute');
        });
      },
      suspend: function suspend(accountId) {
        var _this4 = this;

        return this.client.send('PATCH', "admin/suspend/".concat(accountId)).then(function () {
          return _this4.send('refreshRoute');
        });
      }
    },
    appUrl: Ember.computed(function () {
      if (window.location.origin.includes('accounting-adm-dev')) {
        return window.location.origin.replace("adm-", "");
      } else {
        return window.location.origin.replace("admin", "app");
      }
    })
  });

  _exports.default = _default;
});