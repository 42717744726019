define("admin/models/recurring-journal-entry-line", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amount: (0, _model.attr)('money'),
    is1099Required: (0, _model.attr)('boolean'),
    kind: (0, _model.attr)(),
    name: (0, _model.attr)(),
    journalEntry: (0, _model.belongsTo)('recurringJournalEntry'),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    billToContact: (0, _model.belongsTo)('contact'),
    tags: (0, _model.hasMany)('tag')
  });

  _exports.default = _default;
});