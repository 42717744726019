define("admin/helpers/format-title", ["exports", "admin/utils/titleize"], function (_exports, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTitle = formatTitle;
  _exports.default = void 0;

  function formatTitle(params
  /*, hash*/
  ) {
    return (0, _titleize.default)(params[0]);
  }

  var _default = Ember.Helper.helper(formatTitle);

  _exports.default = _default;
});