define("admin/utils/file-uploader", ["exports", "admin/utils/xhr-states"], function (_exports, _xhrStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    cloudFile: null,
    file: null,
    state: 'waiting',
    progress: 0,
    error: '',
    progressStyle: Ember.computed('progress', function () {
      return Ember.String.htmlSafe("width: ".concat(this.progress, "%"));
    }),
    upload: function upload() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var cloudFile = this.cloudFile;
      var file = this.file;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.set('state', 'preparing');

        if (Ember.testing) {
          Ember.Test.registerWaiter(function () {
            return ['waiting', 'success', 'error'].includes(_this.state);
          });
        } // if the cloud file hasn't been saved, do that first


        var promise;

        if (cloudFile.get('hasDirtyAttributes')) {
          promise = cloudFile.save({
            adapterOptions: opts
          }).then(function () {
            return cloudFile.fetchUploadUrl(opts);
          });
        } else {
          promise = cloudFile.fetchUploadUrl(opts);
        }

        promise.then(function (resp) {
          _this.set('state', 'uploading');

          var url = resp['upload-url'];
          var xhr = new XMLHttpRequest();
          xhr.open('put', url, true);
          xhr.setRequestHeader('Cache-Control', '2592000'); // there are newer/easier apis, but this works with all browsers

          xhr.onreadystatechange = function () {
            if (xhr.readyState === _xhrStates.default.DONE) {
              if (xhr.status === 200) {
                Ember.run(function () {
                  _this.set('state', 'success');

                  cloudFile.set('isUploaded', true); // on success

                  resolve(cloudFile);
                });
              } else {
                var error = xhr.statusText;
                Ember.run(function () {
                  _this.set('state', 'error');

                  _this.set('error', error); // on failure


                  reject(error);
                });
              }
            }
          }; // may not work on all browers, but won't be the end of the world.


          xhr.upload.addEventListener('progress', function (e) {
            Ember.run(function () {
              _this.set('progress', e.loaded / e.total * 100);
            });
          }, false);
          xhr.send(file);
        });
      });
    }
  });

  _exports.default = _default;
});