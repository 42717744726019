define("admin/models/recurring-profile", ["exports", "admin/mixins/line-itemable", "@ember-data/model", "admin/models/model"], function (_exports, _lineItemable, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_lineItemable.default, {
    // internalNotes: DS.attr(),

    /*****************************
     * Options
     ******************************/
    acceptAch: (0, _model.attr)('boolean'),
    acceptCreditCards: (0, _model.attr)('boolean'),
    acceptPaypal: (0, _model.attr)('boolean'),
    autobill: (0, _model.attr)('boolean'),
    autobillInitiated: (0, _model.attr)('boolean'),
    date: Ember.computed.alias('nextScheduledDate'),
    firstSent: (0, _model.attr)('date'),
    frequency: (0, _model.attr)(),
    howMany: (0, _model.attr)('string'),
    includeUnbilled: (0, _model.attr)('boolean'),
    isActive: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    lastSent: (0, _model.attr)('date'),
    nextScheduledDate: (0, _model.attr)('date'),
    remaining: (0, _model.attr)('string'),
    sendInvoice: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    sendOnSave: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sendReceipt: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    startDate: (0, _model.attr)('date'),

    /*****************************
     * Include unbilled preferences
     ******************************/
    includeExpenses: (0, _model.attr)('boolean'),
    includeFutureProjects: (0, _model.attr)('boolean'),
    noProject: (0, _model.attr)('boolean'),
    showDate: (0, _model.attr)('boolean'),
    showNotes: (0, _model.attr)('boolean'),
    showProject: (0, _model.attr)('boolean'),
    showTeamMember: (0, _model.attr)('boolean'),
    timeEntryFormat: (0, _model.attr)(),

    /*****************************
     * Relationships
     ******************************/
    account: (0, _model.belongsTo)('account'),
    contact: (0, _model.belongsTo)('contact'),
    bankAccount: (0, _model.belongsTo)('paymentBankAccount'),
    projects: (0, _model.hasMany)('project'),
    isFinished: Ember.computed('remaining', 'nextScheduledDate', function () {
      return this.remaining == 0;
    })
  });

  _exports.default = _default;
});