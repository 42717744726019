define("admin/helpers/format-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhone = formatPhone;
  _exports.default = void 0;

  function formatPhone(params) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var input = params && params[0] || '';
    var number = input.replace(/[^0-9]/g, ''); // if preserveExistingFormatting is true, we only format if its been normalized to just numbers

    if (opts.preserveExistingFormatting && input !== number) {
      return input;
    }

    var match = number.match(/(\d{0,3})(\d{3})(\d{3})(\d{4})/);

    if (match) {
      match = match.filter(function (s) {
        return s !== '';
      });

      if (match.length === 5) {
        if (opts.dropCountryCode) {
          return "(".concat(match[2], ") ").concat(match[3], "-").concat(match[4]);
        } else {
          return "+".concat(match[1], " (").concat(match[2], ") ").concat(match[3], "-").concat(match[4]);
        }
      } else if (match.length === 4) {
        return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3]);
      } else {
        return match.slice(1).join('-');
      }
    } else {
      if (opts.partial) {
        return number.split('').reduce(function (acc, c, idx) {
          if (idx === 0) {
            return acc.concat(['(', c]);
          }

          if (idx === 3) {
            return acc.concat([')', ' ', c]);
          }

          if (idx === 6) {
            return acc.concat(['-', c]);
          }

          return acc.concat([c]);
        }, []).join('');
      } else {
        return number;
      }
    }
  }

  var _default = Ember.Helper.helper(formatPhone);

  _exports.default = _default;
});