define("admin/services/overlay", ["exports", "admin/utils/seconds-to-read"], function (_exports, _secondsToRead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _items: [],
    // this is to compensate for an apparent bug in ember testing
    // https://github.com/emberjs/ember-qunit/issues/113#issuecomment-300962191
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_items', []);
    },
    showComponent: function showComponent(component, model) {
      var callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var ref = Ember.Object.create({
        component: component,
        model: model,
        callbacks: callbacks
      });
      this.get('_items').pushObject(ref);
      return ref;
    },
    showOrUpdateComponent: function showOrUpdateComponent(component, model) {
      var callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var ref = this._items.find(function (i) {
        return i.component === component || Ember.get(i, 'model.component') === component;
      });

      if (ref) {
        ref.set('model', model);
      } else {
        ref = this.showComponent(component, model, callbacks);
      }

      return ref;
    },
    showBanner: function showBanner() {
      Ember.run.throttle.apply(void 0, [this, 'showBannerThrottled'].concat(Array.prototype.slice.call(arguments), [3000]));
    },
    showBannerThrottled: function showBannerThrottled(style, message) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (style === 'error' && !message) {
        message = 'Something went wrong. Contact us at help@zipbooks.com so we can get it fixed.';
      }

      var ref = this.showComponent('overlays/notification-banner', {
        style: style,
        message: message,
        title: options.title
      });
      var delay = options.delay || (0, _secondsToRead.default)(message && message.string) * 1000 + 1666;
      /* animation time */

      Ember.run.later(this, function () {
        _this.close(ref);
      }, delay);
    },
    showPopover: function showPopover(name, anchor, title, model) {
      var callbacks = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      this.showComponent('overlays/pop-over', {
        component: name,
        anchor: anchor,
        title: title,
        model: model
      }, callbacks);
    },
    showPopout: function showPopout(name, model) {
      var callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.showOrUpdateComponent('overlays/pop-out', {
        component: name,
        model: model
      }, callbacks);
    },
    showDialog: function showDialog(name, title, model) {
      var callbacks = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      this.showComponent('overlays/modal-dialog', {
        component: name,
        title: title,
        model: model
      }, callbacks);
    },
    showDrawer: function showDrawer(name, model) {
      var callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.showComponent(name, model, callbacks);
    },
    isShowing: function isShowing(name) {
      return this._items.any(function (i) {
        return i.component === name || Ember.get(i, 'model.component') === name;
      });
    },
    close: function close(ref, delay, fn) {
      var _this2 = this;

      if (Ember.typeOf(ref) === 'string') {
        ref = this._items.find(function (i) {
          return i.component === ref || Ember.get(i, 'model.component') === ref;
        });

        if (!ref) {
          fn && fn();
          return;
        }
      }

      if (delay && !Ember.testing) {
        Ember.run.later(delay, function () {
          if (!ref.isDestroyed && !ref.isDestroying) {
            _this2.get('_items').removeObject(ref);

            fn && fn();
          }
        });
      } else {
        this.get('_items').removeObject(ref);
        fn && fn();
      }
    }
  });

  _exports.default = _default;
});