define("admin/utils/drawer-link-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = drawerLinkFor;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function drawerLinkFor(params) {
    return params.map(function (param) {
      if (!param) {
        return '';
      } else if (param.get) {
        return "".concat(param.get('modelType'), ".").concat(param.get('id'));
      } else if (_typeof(param) === 'object') {
        return Object.keys(param).map(function (key) {
          var value = param[key];
          return "".concat(key, ".").concat(value);
        }).join('~');
      } else {
        return param;
      }
    }).filter(function (p) {
      return !!p;
    }).join('~');
  }
});