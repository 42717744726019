define("admin/utils/format-seconds-as-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatSecondsAsHours;

  function formatSecondsAsHours(seconds, format) {
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    m = m < 10 ? '0' + m : m;
    var s = Math.floor(seconds % 3600 % 60);
    s = s < 10 ? '0' + s : s;
    var d = seconds / 60 / 60;
    return format.replace(/[hmsd]/g, function (match) {
      switch (match) {
        case 'h':
          return h;

        case 'm':
          return m;

        case 's':
          return s;

        case 'd':
          return d;
      }
    });
  }
});