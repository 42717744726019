define("admin/mixins/paid-modal-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      showPaidModal: function showPaidModal(needs) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        // when using onclick= we have to prevent default ourselves.
        // not all calls to this use that though
        ;
        [].slice.call(arguments).forEach(function (a) {
          if (a && a.preventDefault) {
            a.preventDefault();
            a.stopPropagation();
          }
        });
        this.overlay.showDialog('overlays/dialogs/paid-modal', Ember.String.htmlSafe('<i class="icon-rank-army-star-badge-2-filled"></i>Upgrade Required'), {
          needs: needs,
          afterUpgrade: options.afterUpgrade,
          element: options.analytics
        });
      }
    }
  });

  _exports.default = _default;
});