define("admin/components/filter-select", ["exports", "admin/mixins/labelable", "admin/mixins/testable", "admin/helpers/format-title", "admin/utils/overridable-computed"], function (_exports, _labelable, _testable, _formatTitle, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _labelable.default, {
    classNames: ['select filter-select'],
    labelAttribute: Ember.computed.alias('value'),
    noTestIdPassthrough: true,
    label: true,
    formatTitle: true,
    titles: [],
    maxTitles: 2,
    uniqId: Ember.computed(function () {
      return Ember.guidFor(this);
    }),
    valueLabel: (0, _overridableComputed.default)('valueLabel', 'value', 'defaultValue', function () {
      var _this = this;

      var values = (Ember.isArray(this.value) ? this.value : [this.value]).filter(function (v) {
        return !Ember.isBlank(v);
      }).reduce(function (acc, v) {
        return acc.concat(v.split(',').filter(function (v) {
          return !Ember.isBlank(v);
        }).map(function (v) {
          if (Ember.typeOf(_this.formatTitle) === 'function') {
            return _this.formatTitle(v);
          } else if (Ember.typeOf(_this.formatTitle) === 'boolean') {
            return (0, _formatTitle.formatTitle)([v]);
          } else {
            return v;
          }
        }));
      }, []).filter(function (v) {
        return !Ember.isBlank(v);
      });

      if (values.length > this.maxTitles) {
        return "".concat(values.length, " selected");
      } else {
        var str = values.join(', ');
        return str || this.defaultValue;
      }
    }),
    actions: {
      onClose: function onClose() {
        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});