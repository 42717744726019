define("admin/models/bank-member", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    aggregatedAt: (0, _model.attr)('datetime'),
    errorMessage: (0, _model.attr)(),
    externalId: (0, _model.attr)(),
    institutionCode: (0, _model.attr)(),
    institutionName: (0, _model.attr)(),
    isReconnectable: (0, _model.attr)('boolean'),
    logoUrl: (0, _model.attr)(),
    provider: (0, _model.attr)(),
    status: (0, _model.attr)(),
    syncTo: (0, _model.attr)(),
    bankAccounts: (0, _model.hasMany)('bankAccounts')
  });

  _exports.default = _default;
});