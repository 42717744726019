define("admin/mixins/browser-fixes", ["exports", "admin/utils/is-browser", "admin/utils/adjust-nav-position"], function (_exports, _isBrowser, _adjustNavPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super();

      Ember.run.schedule('afterRender', this, function () {
        $('.sidebar-nav').on('animationend MSAnimationEnd webkitAnimationEnd', function () {
          Ember.run.later(_this, function () {
            (0, _adjustNavPosition.default)();
          }, 200);
        });
        $(window).resize(function () {
          (0, _adjustNavPosition.default)();
        }); // Add browser class name to HTML tag for any browser-specific CSS fixes

        $('document').ready(function () {
          var _this2 = this;

          (0, _isBrowser.browserNames)().forEach(function (name) {
            return $(_this2).find('html').addClass(name);
          });
        });
      });
    }
  });

  _exports.default = _default;
});