define("admin/utils/push-payload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pushPayload;

  function pushPayload(store, modelName, rawPayload) {
    var ModelClass = store.modelFor(modelName);
    var serializer = store.serializerFor(modelName);
    var jsonApiPayload = serializer.normalizeResponse(store, ModelClass, rawPayload, null, 'query');
    return store.push(jsonApiPayload);
  }
});