define("admin/controllers/main/super/devops/jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['queue'],
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      generateReport: function generateReport(queue) {
        var _this = this;

        return this.client.POST("admin/devops/generate-jobs-report", null, {
          queue: queue
        }).then(function () {
          _this.overlay.showBanner('success', "A report is being generated and the results will be logged. Search the logs for \"report\".");
        });
      }
    }
  });

  _exports.default = _default;
});