define("admin/services/sibling-url-cache", ["exports", "admin/services/url-cache"], function (_exports, _urlCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _urlCache.default.extend({});

  _exports.default = _default;
});