define("admin/utils/is-control-key", ["exports", "admin/utils/keycodes"], function (_exports, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isControlKey;

  function isControlKey(which) {
    var except = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var reseverdKeyCodes = Object.keys(_keycodes.default).filter(function (c) {
      return !['BACKSPACE', 'DELETE'].concat(except).includes(c);
    }).map(function (k) {
      return _keycodes.default[k];
    });
    return reseverdKeyCodes.includes(which);
  }
});