define("admin/transforms/download-url", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    imageCache: Ember.inject.service(),
    // because downloadUrls are signed, the url is different every time. In order to cache them,
    // we use the first download url we get
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return serialized;
      }

      var baseUrl = serialized.split('?')[0];
      return this.imageCache.cached(baseUrl, serialized);
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});