define("admin/components/failable-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      badUrl: function badUrl() {
        if (!this.isDestroyed && !this.isDestroying) {
          this.set('badUrl', true);
        }
      }
    },
    loadImage: Ember.computed('url', 'badUrl', function () {
      return !!this.url && !this.badUrl;
    })
  });

  _exports.default = _default;
});