define("admin/components/multi-file-upload", ["exports", "admin/components/file-upload", "admin/utils/secure-downloader"], function (_exports, _fileUpload, _secureDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileUpload.default.extend({
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    cloudFiles: [],
    hasFullPermission: true,
    actions: {
      removeFile: function removeFile(cloudFile, e) {
        var _this = this;

        e.preventDefault();
        e.stopPropagation();
        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          type: 'file'
        }, {
          destroy: function destroy() {
            _this.cloudFiles.removeObject(cloudFile);

            Ember.tryInvoke(_this, 'didUpload', [_this.cloudFiles, {
              removed: cloudFile
            }]);
          }
        });
        return false;
      },
      clearError: function clearError(uploader) {
        this.uploaders.removeObject(uploader);
      },
      downloadFile: function downloadFile(cloudFile) {
        (0, _secureDownloader.default)(cloudFile);
      },
      couldNotLoadImage: function couldNotLoadImage(cloudFile) {
        cloudFile.set('isImage', false);
      }
    },
    currentCloudFiles: Ember.computed('cloudFiles.@each', 'cloudFile', function () {
      if (this.cloudFile) {
        return [this.cloudFile];
      }

      return this.cloudFiles;
    }),
    // overridden
    prepareFiles: function prepareFiles(files, done) {
      if (this.limit) {
        var allowed = Math.max(0, this.limit - this.cloudFiles.length);

        if (files.length > allowed) {
          files = files.slice(0, allowed);
        }
      }

      done(files);
    },
    setUploaders: function setUploaders(uploaders) {
      this.set('uploaders', uploaders);
    },
    didFinishUploading: function didFinishUploading(cloudFiles) {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.cloudFiles.pushObjects(cloudFiles);
      cloudFiles.forEach(function (cf) {
        if (cf.isImage) {
          var reader = new FileReader();
          reader.addEventListener('load', function () {
            Ember.run(function () {
              cf.set('downloadUrl', reader.result);
            });
          }, false);

          var uploader = _this2.uploaders.find(function (u) {
            return u.cloudFile.id === cf.id;
          });

          reader.readAsDataURL(uploader.file);
        }
      });
    }
  });

  _exports.default = _default;
});