define("admin/utils/format-money", ["exports", "admin/utils/currencies", "admin/utils/parse-number"], function (_exports, _currencies, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatMoney;

  function formatMoney(input) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (options.onlyIfNumber) {
      // if it starts with something besides a number, just return input
      if (input.replace(' ', '').match(/[^0-9-+.,]/g)) {
        return input;
      }
    }

    var number = (0, _parseNumber.default)(input);

    if (isNaN(number)) {
      number = 0;
    }

    if (options.absolute) {
      number = Math.abs(number);
    }

    if (options.roundIfEven && Math.floor(number) === number) {
      options.precision = 0;
    }

    options = Ember.assign({
      accountingStyle: false,
      precision: 2,
      currency: 'USD',
      accountCurrency: 'USD',
      escaped: true,
      hideCurrencyCode: false
    }, options); // Handling those darn negative zeroes

    if (number.toFixed(4) === '-0.0000') {
      number = 0;
    }

    var symbol = _currencies.default[options.currency]; // If the currency is not the default account currency
    // AND the symbol is not simply the three-letter code, then display the code before the symbol
    // AND "hideCode" is not set to true

    if (options.currency !== options.accountCurrency && _currencies.default[options.currency] !== undefined && options.currency !== symbol && options.hideCurrencyCode !== true) {
      symbol = options.currency + symbol;
    }

    if (!options.escaped) {
      symbol = $('<textarea />').html(symbol).text();
    }

    var moneyString;
    var formatOptions = {
      precision: options.precision,
      symbol: symbol // "accounting" style

    };

    if (options.accountingStyle === true) {
      formatOptions.format = {
        pos: '%v',
        neg: '(%v)',
        zero: '%v'
      };
    } else {
      formatOptions.format = {
        pos: '%s%v',
        neg: '-%s%v',
        zero: '%s%v' // these symbols dont show correctly with certain styles so
        // we apply this class to override those styles (only when html escaped)

      };

      if (options.escaped && ['MUR', 'LKR', 'PKR'].indexOf(options.currency) > -1) {
        formatOptions.format = '<span class="symbol-safe">%s</span>%v';
      }
    }

    moneyString = accounting.formatMoney(number, formatOptions); // }

    if (options.shortThousand && moneyString.lastIndexOf(',') > 0) {
      moneyString = moneyString.slice(0, moneyString.lastIndexOf(',')) + 'k';
    }

    return moneyString;
  }
});