define("admin/models/identity", ["exports", "@ember-data/model", "admin/models/model", "admin/utils/initials"], function (_exports, _model, _model2, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    colorblind: (0, _model.attr)('boolean'),
    dateOfBirth: (0, _model.attr)('date'),
    email: (0, _model.attr)(),
    firstName: (0, _model.attr)(),
    isAdvanced: (0, _model.attr)('boolean'),
    isEmailVerified: (0, _model.attr)('boolean'),
    lastName: (0, _model.attr)(),
    lastAcceptedTos: (0, _model.attr)('datetime'),
    socialConnections: (0, _model.attr)(),
    userTokens: (0, _model.attr)(),
    // admin permissions
    permissions: (0, _model.attr)(),
    requireEmail: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    sendInvite: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    avatarCloudFile: (0, _model.belongsTo)('cloudFile'),
    users: (0, _model.hasMany)('users'),
    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName || '';
      var lastName = this.lastName || '';

      if (this.firstName || this.lastName) {
        return "".concat(firstName, " ").concat(lastName).trim();
      }
    }),
    displayName: Ember.computed('name', 'email', function () {
      return this.name || this.email;
    }),
    initials: Ember.computed('name', 'email', function () {
      return (0, _initials.default)(this.name, {
        fallback: this.email
      });
    }),
    accountantToken: Ember.computed('userTokens.@each.type', function () {
      if (this.userTokens) {
        return this.userTokens.find(function (t) {
          return t.type === 'accountant';
        });
      }
    })
  });

  _exports.default = _default;
});