define("admin/models/account-statement", ["exports", "admin/models/model", "@ember-data/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    cash: (0, _model2.attr)(),
    creditCardSpend: (0, _model2.attr)(),
    expenses: (0, _model2.attr)(),
    month: (0, _model2.attr)(),
    name: (0, _model2.attr)(),
    accountant: (0, _model2.attr)(),
    plan: (0, _model2.attr)(),
    signupDate: (0, _model2.attr)(),
    token: (0, _model2.attr)(),
    netIncome: (0, _model2.attr)(),
    sales: (0, _model2.attr)(),
    state: (0, _model2.attr)(),
    transactionCount: (0, _model2.attr)()
  });

  _exports.default = _default;
});