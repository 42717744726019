define("admin/services/graphql", ["exports", "admin/utils/request-log", "admin/utils/error-reporter", "admin/config/environment", "admin/utils/redirect"], function (_exports, _requestLog, _errorReporter, _environment, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    host: _environment.default.API_URL || window.location.origin,
    session: Ember.inject.service(),
    send: function send(query) {
      var _this2 = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var _this = this;

      var url = "".concat(this.host, "/ve/graphql");
      var body = {
        query: query
      };

      if (opts.variables) {
        body.variables = opts.variables;
      }

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          method: 'POST',
          url: url,
          data: JSON.stringify(body),
          dataType: 'json',
          headers: _this2.headers(opts),
          success: function success(data, _textStatus, jqXHR) {
            _requestLog.default.log('POST', url, jqXHR.status, jqXHR.responseJSON);

            if (opts.includeXHR) {
              return resolve([data, jqXHR]);
            } else {
              return resolve(data);
            }
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            // leave as function for `...arguments` below
            _errorReporter.default.breadcrumb({
              message: 'AJAX client.js error',
              category: 'action',
              data: {
                method: "POST",
                url: url,
                jqXHR: jqXHR,
                textStatus: textStatus,
                errorThrown: errorThrown
              }
            });

            if (jqXHR.status === 503) {
              if (!(0, _redirect.default)('/maintenance')) {
                return reject(jqXHR.responseJSON, jqXHR);
              }
            }

            if (jqXHR.status === 401) {
              _this.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


              (0, _redirect.default)('/login');
            }

            if (jqXHR.status === 402) {
              Ember.getOwner(_this).lookup('router:main').transitionTo('main.settings.subscription');
            }

            if (jqXHR.status === 429 && jqXHR.responseJSON.errors) {
              var emailVerifyError = jqXHR.responseJSON.errors.find(function (e) {
                return e.id == 'email-not-verified';
              });

              if (emailVerifyError) {
                _this.session.handleEmailNotVerified(emailVerifyError);
              }
            }

            _requestLog.default.log('POST', url, jqXHR.status, jqXHR.responseJSON);

            return Ember.run.apply(void 0, [null, reject].concat(Array.prototype.slice.call(arguments)));
          },
          statusCode: {
            401: function _() {
              Ember.run(function () {
                _this2.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


                (0, _redirect.default)('/login');
              });
            },
            400: function _(jqXHR, _textStatus, _errorThrown) {
              Ember.run(function () {
                if (jqXHR.responseJSON.error && jqXHR.responseJSON.error === 'token_not_provided') {
                  _this2.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


                  (0, _redirect.default)('/login');
                }
              });
            }
          }
        });
      });
      return promise;
    },
    headers: function headers(opts) {
      var headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      };
      var token = opts.token || this.get('session.token');

      if (token) {
        headers['Authorization'] = "Bearer ".concat(token);
      }

      return headers;
    }
  });

  _exports.default = _default;
});