define("admin/instance-initializers/token-login", ["exports", "admin/utils/pluck-query-param"], function (_exports, _pluckQueryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var session = appInstance.lookup('service:session'); // if a token is in the url, we should use it. It might not get handled
    // by the applicaiton observer until this is done

    var token = (0, _pluckQueryParam.default)('token');

    if (token) {
      session.login(token);
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});