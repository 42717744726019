define("admin/models/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    archivedAt: (0, _model.attr)('datetime'),
    deletedAt: (0, _model.attr)('datetime'),
    createdAt: (0, _model.attr)('datetime'),
    updatedAt: (0, _model.attr)('datetime'),
    isArchived: Ember.computed('archivedAt', function () {
      return this.archivedAt !== null && typeof this.archivedAt !== 'undefined';
    }),
    toggleArchived: function toggleArchived() {
      if (this.isArchived) {
        this.set('archivedAt', null);
      } else {
        this.set('archivedAt', new Date());
      }
    },

    get modelType() {
      return (this.modelName || this.get('_internalModel.modelName')).toLowerCase();
    },

    fresh: function fresh() {
      if (this.id && this.modelType) {
        return this.store.peekRecord(this.modelType, this.id);
      }

      return null;
    }
  });

  _exports.default = _default;
});