define("admin/mixins/refreshable-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    urlCache: Ember.inject.service(),
    actions: {
      refreshRoute: function refreshRoute() {
        this.urlCache.clear();
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});