define("admin/components/accountant/my-books-side-bar", ["exports", "admin/components/nav-side-bar"], function (_exports, _navSideBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _navSideBar.default.extend({
    mapping: {
      'reports.income-statement': 'reports.financial-statements',
      'reports.income-statement-percent-of-sales': 'reports.financial-statements',
      'reports.balance-sheet': 'reports.financial-statements',
      'reports.cash-flow-statement': 'reports.financial-statements',
      'reports.taxes-invoiced': 'reports.accounting-tax',
      'reports.general-journal': 'reports.accounting-tax',
      'reports.general-ledger': 'reports.accounting-tax',
      'reports.trial-balance': 'reports.accounting-tax',
      'reports.accounts-receivable': 'reports.sales',
      'reports.client-report': 'reports.sales',
      'reports.invoice-summary': 'reports.sales',
      'reports.sales-summary': 'reports.sales',
      'reports.credit-card-processing': 'reports.sales',
      'reports.expense-summary': 'reports.expenses',
      'reports.contractor-summary': 'reports.expenses',
      'reports.billable-expenses': 'reports.expenses',
      'reports.user-summary': 'reports.teams-tracking',
      'reports.time-tracking': 'reports.teams-tracking',
      'reports.transaction-tracking': 'reports.teams-tracking',
      reports: 'reports',
      transactions: 'transactions',
      'bank-accounts': 'transactions',
      reconciliations: 'transactions',
      reconciliation: 'transactions',
      'chart-accounts': 'transactions',
      labels: 'transactions',
      bills: 'transactions',
      bill: 'transactions',
      contacts: 'contacts',
      contact: 'contacts',
      dashboard: 'dashboard',
      '': 'dashboard'
    }
  });

  _exports.default = _default;
});