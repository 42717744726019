define("admin/mixins/reports/balance-sheet-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    queryParams: ['start_date', 'end_date', 'group_by', 'basis', 'include'],
    start_date: moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    group_by: 'total',
    columnCount: Ember.computed('model.statement.groups', function () {
      return Object.keys(this.get('model.statement.groups')).length;
    }),
    actions: {
      quickReport: function quickReport(kind) {
        if (kind === 'current') {
          var start = moment().startOf('year').format('YYYY-MM-DD');
          var end = moment().format('YYYY-MM-DD');
          this.transitionToRoute({
            queryParams: {
              start_date: start,
              end_date: end
            }
          });
        } else if (kind === 'last_year') {
          var _start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');

          var _end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start,
              end_date: _end
            }
          });
        } else if (kind === 'month_to_date') {
          var _start2 = moment().startOf('month').format('YYYY-MM-DD');

          var _end2 = moment().format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start2,
              end_date: _end2
            }
          });
        } else if (kind === 'last_month') {
          var _start3 = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

          var _end3 = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start3,
              end_date: _end3
            }
          });
        }
      }
    },
    balanceSheetWithStyles: Ember.computed('model.statement.balance_sheet', function () {
      var balanceSheet = this.get('model.statement.balance_sheet');
      this.addStyles(balanceSheet);
      return balanceSheet;
    }),
    addStyles: function addStyles(balanceSheet, parentAccount) {
      var _this = this;

      balanceSheet.forEach(function (account) {
        account['style_classes'] = {
          balance: '',
          total: '',
          total_titles: '',
          balance_values: '' // Define big three and their colors

        };
        var bigThree = {
          Assets: 'blue',
          Liabilities: 'red',
          Equity: 'purple' // Determine if an account is big three

        };

        var isBigThree = function isBigThree(account) {
          return Object.keys(bigThree).indexOf(account.name) !== -1 && account.is_main;
        }; // so we don't have to handle undefined parent accounts, less nesting


        if (typeof parentAccount === 'undefined') {
          parentAccount = {};
        }
        /** Borders **/
        // Balance lines: top border if parent account is big 3
        // Total lines: top border if account itself is big 3

        /** Colors **/
        // On balance lines: show color if parent account is Big 3 and it has no children
        // On total lines: show color if parent account is Big 3
        // On Liabilities and Equity total, color blue

        /** Margins **/
        // On total lines: margin bottom if account is big 3

        /** Bold **/
        // On total lines: bold if account is big 3

        /** Hide **/
        // Total lines: hide if parent account is not big 3 AND if account is not big 3
        // Balance lines: hide balance value if is big 3 and balance is $0


        if (isBigThree(parentAccount)) {
          account['style_classes']['total'] += bigThree[parentAccount.classification] + ' ';
          account['style_classes']['balance'] += 'balance-sheet-line-top-border ';

          if (account.children.length === 0) {
            account['style_classes']['balance'] += bigThree[parentAccount.classification] + ' ';
          }
        }

        if (isBigThree(account)) {
          account['style_classes']['total'] += 'mb-2 ';
          account['style_classes']['total'] += 'font-weight-bold ';
          account['style_classes']['total'] += bigThree[account.classification] + ' ';
          account['style_classes']['total'] += 'balance-sheet-line-top-border ';

          if (account.balance === 0) {
            account['style_classes']['balance_values'] += 'v-hidden ';
          }
        }

        if (!isBigThree(account) && !isBigThree(parentAccount)) {
          account['style_classes']['total'] = 'hidden ';
        }

        if (account.name === 'Liabilities and Equity total' && account.is_main) {
          account['style_classes']['balance'] += 'font-weight-bold blue ';
        } // Recurse through children


        if (_typeof(account.children) === 'object') {
          _this.addStyles(account.children, account);
        }
      });
    }
  });

  _exports.default = _default;
});