define("admin/controllers/main/sales/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      finish: function finish() {
        var _this = this;

        return this.client.POST('/admin/sales/finish-gvoice', null, {
          'url-with-code': this.gvoiceCallbackUrl
        }).then(function () {
          _this.send('refreshRoute');
        });
      }
    }
  });

  _exports.default = _default;
});