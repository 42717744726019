define("admin/templates/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Cxc4B4o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Sorry!\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"error\",\"debug\",\"message\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"error\",\"debug\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"We're doing some maintenance on the site. We should be back up in a few minutes.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/logo.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/maintenance.hbs"
    }
  });

  _exports.default = _default;
});