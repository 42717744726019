define("admin/helpers/exists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exists = exists;
  _exports.default = void 0;

  function exists(params
  /*, hash*/
  ) {
    if (!params[0] || !params[1]) {
      return false;
    }

    return params[0].any(function (item
    /*, index, enumerable*/
    ) {
      if (item.get && item.get('id')) {
        return item.get('id') === params[1].get('id');
      } else {
        return item === params[1];
      }
    });
  }

  var _default = Ember.Helper.helper(exists);

  _exports.default = _default;
});