define("admin/components/accountant/my-practice-side-bar", ["exports", "admin/components/nav-side-bar"], function (_exports, _navSideBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _navSideBar.default.extend({
    mapping: {
      'accountant.clients': 'clients',
      'accountant.clients.defaults': 'clients',
      'accountant.clients.category-templates': 'clients',
      'accountant.clients.category-template': 'clients',
      'settings.admin': 'settings',
      'settings.company': 'settings',
      'settings.payments': 'settings',
      'settings.preferences': 'settings',
      'settings.subscription': 'settings',
      'settings.team': 'settings',
      documents: 'settings',
      locations: 'settings',
      location: 'settings',
      integrations: 'settings',
      'accept-payments': 'settings',
      invoices: 'invoices',
      invoice: 'invoices',
      estimates: 'invoices',
      estimate: 'invoices',
      'recurring-profiles': 'invoices',
      'recurring-profile': 'invoices',
      items: 'invoices',
      reminders: 'invoices',
      projects: 'tracking',
      project: 'tracking',
      'time-entries': 'tracking',
      'time-and-pay': 'tracking',
      '': 'dashboard'
    }
  });

  _exports.default = _default;
});