define("admin/templates/components/over-lay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x69doN0Z",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"overlay\",\"_items\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"model\",\"callbacks\",\"ref\"],[[23,1,[\"model\"]],[23,1,[\"callbacks\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/over-lay.hbs"
    }
  });

  _exports.default = _default;
});