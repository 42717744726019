define("admin/services/accountant-session", ["exports", "ember-data", "admin/mixins/sessions"], function (_exports, _emberData, _sessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_sessions.default, {
    // when accountants operate inside a client account, they can both operate as a user of the current account
    // and also a user of their firms account. The authentication information about the accountant firm user is
    // included in the identity that is returned with the current accounts user info.
    session: Ember.inject.service(),
    accountantStore: Ember.inject.service(),
    accountantClient: Ember.inject.service(),
    token: Ember.computed.alias('session.currentUser.identity.accountantToken.token'),
    currentUser: Ember.computed('token', function () {
      var _this = this;

      return _emberData.default.PromiseObject.create({
        promise: this.accountantClient.GET('users', 'me').then(function (resp) {
          // make a deep copy because serializers modify the payload in place
          var payload = $.extend(true, {}, resp);

          _this.accountantStore.pushPayload(payload);

          var user = _this.accountantStore.peekRecord('user', resp.data.id);

          return user;
        })
      });
    }),
    logout: function logout() {
      this.set('currentUser', null);
      this.accountantStore.unloadAll();
    }
  });

  _exports.default = _default;
});