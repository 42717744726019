define("admin/controllers/main/support/account-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    actions: {
      deleteSubscription: function deleteSubscription(id) {
        return this.client.POST("admin/support/delete-subscription", null, {
          id: id
        }).then(function () {
          window.location.reload();
        });
      },
      deleteIntegration: function deleteIntegration(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            id = _ref2[0],
            type = _ref2[1];

        return this.client.POST("admin/support/delete-integration", null, {
          id: id,
          type: type
        }).then(function () {
          window.location.reload();
        });
      },
      addToMazuma: function addToMazuma(accountId) {
        return this.client.POST("admin/support/add-to-mazuma", null, {
          account_id: accountId
        }).then(function () {
          window.location.reload();
        });
      },
      copyChartAccounts: function copyChartAccounts(accountId) {
        var ids = this.copyToAccounts.split(/[^\d]+/).map(function (i) {
          return i * 1;
        });
        return this.client.POST("admin/support/copy-coa", null, {
          account_id: accountId * 1,
          copy_to_account_ids: ids
        }).then(function () {
          window.location.reload();
        });
      },
      copyTemplateGroup: function copyTemplateGroup(accountId) {
        var ids = this.copyTemplateGroupToAccounts.split(/[^\d]+/).map(function (i) {
          return i * 1;
        });
        return this.client.POST("admin/support/copy-template-group", null, {
          account_id: accountId * 1,
          copy_to_account_ids: ids
        }).then(function () {
          window.location.reload();
        });
      },
      convertToClients: function convertToClients(accountId) {
        var ids = this.clientIds.split(/[^\d]+/).map(function (i) {
          return i * 1;
        });
        return this.client.POST("admin/support/convert-to-clients", null, {
          account_id: accountId * 1,
          client_account_ids: ids
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});