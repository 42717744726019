define("admin/mixins/controller-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page'],
    page: 1,
    nextPage: Ember.computed('page', function () {
      return this.page + 1;
    }),
    prevPage: Ember.computed('page', function () {
      return this.page - 1;
    })
  });

  _exports.default = _default;
});