define("admin/utils/overridable-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = overridableComputed;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function overridableComputed() {
    var args = [].slice.call(arguments);
    var key = args.shift();
    var fn = args.pop();
    var overriddenKey = "_overridden".concat(key);
    return Ember.computed.apply(void 0, [overriddenKey].concat(_toConsumableArray(args), [{
      get: function get(_key) {
        return this[overriddenKey] || fn.call(this);
      },
      set: function set(_key, value) {
        this.set(overriddenKey, value);
        return value;
      }
    }]));
  }
});