define("admin/helpers/format-time-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTimeLength = formatTimeLength;
  _exports.default = void 0;

  function formatTimeLength(params
  /*, hash*/
  ) {
    var seconds = params[0];
    var hours = Math.floor(seconds / 3600);
    var min = Math.floor((seconds - hours * 3600) / 60);

    if (hours > 0) {
      return hours + ' ' + (hours === 1 ? 'hr' : 'hrs');
    }

    if (min > 0) {
      return min + ' ' + (min === 1 ? 'min' : 'mins');
    }

    return 'less than a min.';
  }

  var _default = Ember.Helper.helper(formatTimeLength);

  _exports.default = _default;
});