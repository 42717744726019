define("admin/components/avatar-image", ["exports", "admin/components/failable-image", "admin/utils/initials", "admin/utils/overridable-computed"], function (_exports, _failableImage, _initials, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _failableImage.default.extend({
    initials: (0, _overridableComputed.default)('initials', function () {
      if (this.name) {
        return (0, _initials.default)(this.name);
      }
    })
  });

  _exports.default = _default;
});