define("admin/templates/components/filter-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iLBPu2Rc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[24,[\"valueLabel\"]],[24,[\"placeholder\"]],[28,\"concat\",[\"form-control \",[28,\"if\",[[28,\"not\",[[24,[\"notSmall\"]]],null],\"form-control-small \"],null],[24,[\"positionClass\"]]],null]]]],false],[0,\"\\n\"],[7,\"label\",true],[11,\"class\",[29,[\"label-inside \",[28,\"unless\",[[24,[\"animateLabel\"]],\"no-animation\"],null],\" \",[22,\"fadeClass\"]]]],[8],[1,[22,\"labelString\"],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"id\",[28,\"concat\",[\"filter-select-\",[24,[\"uniqId\"]]],null]],[10,\"class\",\"filter-select-mask\"],[8],[9],[0,\"\\n\\n\"],[4,\"drop-down\",null,[[\"for\",\"clickInsideKeepOpen\",\"onClose\",\"class\",\"disabled\"],[[28,\"concat\",[\"filter-select-\",[24,[\"uniqId\"]]],null],[24,[\"clickInsideKeepOpen\"]],[28,\"action\",[[23,0,[]],\"onClose\"],null],[24,[\"dropdownMenuClass\"]],[24,[\"disabled\"]]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"hideArrow\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"icon-down-arrow select-arrow \",[28,\"if\",[[24,[\"disabled\"]],\"transparent\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/filter-select.hbs"
    }
  });

  _exports.default = _default;
});