define("admin/helpers/param-exists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paramExists = paramExists;
  _exports.default = void 0;

  function paramExists(params, _hash) {
    var value = params[1];
    var current = params[0];

    if (current) {
      var array = current.split(',');
      return array.includes(value);
    } else {
      return current === value;
    }
  }

  var _default = Ember.Helper.helper(paramExists);

  _exports.default = _default;
});