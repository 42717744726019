define("admin/components/single-item-form", ["exports", "admin/mixins/testable", "admin/utils/keycodes"], function (_exports, _testable, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: ['position-relative single-item-form'],
    noTestIdPassthrough: true,
    saveOnEnter: true,
    actions: {
      edit: function edit() {
        var _this = this;

        this.set('isEditing', true);
        Ember.run.next(function () {
          _this.$('input:first').focus();
        });
      },
      save: function save() {
        var _this2 = this;

        var promise;

        if (this.onSave) {
          promise = this.onSave();
        } else {
          promise = this.model.save();
        }

        if (promise && promise.then) {
          this.set('buttonState', 'saving');
          promise.then(function () {
            _this2.set('buttonState', 'loaded');

            Ember.run.later(_this2, function () {
              _this2.set('isEditing', false);
            }, 1000);
          }, function () {
            _this2.set('buttonState', 'invalid');
          });
        } else {
          this.set('isEditing', false);
        }
      }
    },
    keyPress: function keyPress(event) {
      if (this.saveOnEnter && event.which === _keycodes.default.ENTER) {
        return this.send('save');
      }
    },
    _closeWhenEditingNotAllowed: Ember.observer('allowEditing', function () {
      this.set('isEditing', false);
    })
  });

  _exports.default = _default;
});