define("admin/utils/throttle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = throttle;

  function throttle(context, func, interval) {
    for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
      args[_key - 3] = arguments[_key];
    }

    // what we want is it to fetch results if this hasn't been called recently,
    if (!context[func + '_lastCalled']) {
      context[func + '_hasCoalescedCalls'] = false;
      context[func + '_lastCalled'] = true;
      context[func].apply(context, args);
      Ember.run.later(this, function () {
        if (context[func + '_hasCoalescedCalls']) {
          context[func].apply(context, args);
        }

        context[func + '_hasCoalescedCalls'] = false;
        context[func + '_lastCalled'] = false;
      }, interval);
      return true;
    } // OR if it has been called recently only once after the period elapses
    else if (!context[func + '_hasCoalescedCalls']) {
        context[func + '_hasCoalescedCalls'] = true;
      }

    return false;
  }
});