define("admin/models/team-account", ["exports", "@ember-data/model", "admin/models/model", "admin/utils/initials"], function (_exports, _model, _model2, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    defaultBccAddress: (0, _model.attr)(),
    deletable: (0, _model.attr)('boolean'),
    owner: (0, _model.attr)('boolean'),
    permissionsMap: (0, _model.attr)(),
    logoCloudFile: (0, _model.belongsTo)('cloudFile'),
    users: (0, _model.hasMany)('user'),
    initials: Ember.computed('name', 'defaultBccAddress', function () {
      return (0, _initials.default)(this.name, {
        fallback: this.defaultBccAddress
      });
    })
  });

  _exports.default = _default;
});