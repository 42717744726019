define("admin/helpers/estimates-called", ["exports", "admin/utils/format-called"], function (_exports, _formatCalled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    onUpdate: Ember.observer('session.account.settings.estimatesCalled', function () {
      this.recompute();
    }),
    compute: function compute(params, hash) {
      var account = hash.account || this.get('session.account') || Ember.Object.create();
      return (0, _formatCalled.default)(account.get('settings.estimatesCalled') || 'estimates', params);
    }
  });

  _exports.default = _default;
});