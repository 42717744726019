define("admin/services/image-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _cache: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_cache', []);
    },
    cached: function cached(key, url) {
      if (!this._cache[key]) {
        this._cache[key] = url;
      }

      return this._cache[key];
    },
    clear: function clear() {
      this._cache = {};
    }
  });

  _exports.default = _default;
});