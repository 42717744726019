define("admin/utils/parse-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseHour;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var normalizedUnit = {
    am: 'am',
    a: 'am',
    pm: 'pm',
    p: 'pm'
  };

  function parseHour(string) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      pmLessThan: 6
    };
    var match = string.match(/^(10|11|12|[1-9])(:|)(\d{1,2}|)( |)(pm|p|am|a|)$/);

    if (match) {
      var _match = _slicedToArray(match, 6),
          _e = _match[0],
          h = _match[1],
          _colon = _match[2],
          m = _match[3],
          _space = _match[4],
          unit = _match[5];

      m = m === '' ? '00' : m;
      m = m.length < 2 ? m + '0' : m;

      if (unit === '') {
        var hour = h * 1;

        if (opts.pmLessThan && hour < opts.pmLessThan || opts.pmGreaterThan && hour > opts.pmGreaterThan) {
          return moment("".concat(h).concat(m, "pm"), 'hmma');
        } else {
          return moment("".concat(h).concat(m, "am"), 'hmma');
        }
      } else {
        unit = normalizedUnit[unit];
        return moment("".concat(h).concat(m).concat(unit), 'hmma');
      }
    }
  }
});