define("admin/templates/components/address-formatted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZfQ+ZnoA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"address\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[22,\"address_1\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"address_2\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[22,\"address_2\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[22,\"city\"],false],[4,\"if\",[[28,\"and\",[[24,[\"city\"]],[24,[\"state\"]]],null]],null,{\"statements\":[[0,\",\"]],\"parameters\":[]},null],[0,\" \"],[1,[22,\"state\"],false],[0,\" \"],[1,[22,\"postalCode\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"country\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[22,\"country\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"phone\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[22,\"phone\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/address-formatted.hbs"
    }
  });

  _exports.default = _default;
});