define("admin/routes/main/support/admin-login", ["exports", "admin/mixins/refreshable-route"], function (_exports, _refreshableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, {
    client: Ember.inject.service(),
    model: function model(params) {
      var queryParams = {
        search: params.searchText,
        search_field: params.searchField
      };
      return this.client.cached('GET', "admin/support/user-search?".concat($.param(queryParams)));
    },
    afterModel: function afterModel() {
      this.addFocus();
    },
    addFocus: function addFocus() {
      var _this = this;

      if ($('.search-text-field').length) {
        $('.search-text-field input:first').focus();
      } else {
        Ember.run.later(this, function () {
          return _this.addFocus();
        }, 100);
      }
    }
  });

  _exports.default = _default;
});