define("admin/mixins/stripe-payment-form", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupStripe: function setupStripe(elementStyles, elementClasses) {
      if ($('#stripe-card-number').length === 0) {
        return;
      }

      if (!elementStyles) {
        //style defaults
        elementStyles = {
          base: {
            color: '#272C35',
            fontFamily: 'Karla, Open Sans, Segoe UI, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            fontWeight: 'normal',
            '::placeholder': {
              color: '#A7AEBA'
            }
          },
          invalid: {
            color: '#ff194a',
            ':focus': {
              color: '#ff194a'
            }
          }
        };
      }

      var stripe = Stripe(_environment.default.stripe.publishableKey);
      this.set('stripe', stripe);
      var elements = this.stripe.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Karla'
        }]
      });
      var cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
        placeholder: 'Card number'
      });
      cardNumber.mount('#stripe-card-number');
      var cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses
      });
      cardExpiry.mount('#stripe-card-expiry');
      var cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses
      });
      cardCvc.mount('#stripe-card-cvc');
      this.set('elements', [cardNumber, cardExpiry, cardCvc]);
    }
  });

  _exports.default = _default;
});