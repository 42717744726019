define("admin/controllers/main", ["exports", "admin/utils/redirect"], function (_exports, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }),
    hideLayout: Ember.computed('router.currentPath', function () {
      var router = this.router;
      var routeName = router.get('currentPath');
      return ['main.sales.stats'].includes(routeName);
    }),
    actions: {
      logout: function logout() {
        (0, _redirect.default)('/login');
      }
    }
  });

  _exports.default = _default;
});