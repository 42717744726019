define("admin/utils/later", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = later;

  function later(delay, func) {
    if (Ember.testing) {
      func();
      return;
    }

    Ember.run.later(this, func, delay);
  }
});