define("admin/templates/components/table-sort-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DE9SAOHn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-sort-link white-space-nowrap\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"query\"],[[28,\"hash\",null,[[\"direction\",\"page\"],[[24,[\"oppositeSortDirection\"]],1]]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"row small-gutters\"],[8],[0,\"\\n\"],[0,\"        \"],[1,[28,\"format-title\",[[24,[\"titleText\"]]],null],false],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[29,[\"ml-2 arrow arrow-\",[28,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"asc\"],null],\"up\",\"down\"],null]]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"query\"],[[28,\"hash\",null,[[\"sort\",\"page\"],[[24,[\"attribute\"]],1]]]]],{\"statements\":[[1,[28,\"format-title\",[[24,[\"titleText\"]]],null],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"hidden ml-2 arrow arrow-\",[28,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"asc\"],null],\"up\",\"down\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/table-sort-link.hbs"
    }
  });

  _exports.default = _default;
});