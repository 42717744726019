define("admin/components/auto-suggest", ["exports", "admin/utils/keycodes", "admin/utils/is-control-key", "admin/mixins/testable", "admin/mixins/labelable", "admin/utils/later", "admin/utils/insensitive-compare", "admin/utils/throttle", "ember-data", "admin/utils/slow-array", "admin/utils/uuid", "admin/utils/overridable-computed"], function (_exports, _keycodes, _isControlKey, _testable, _labelable, _later, _insensitiveCompare, _throttle, _emberData, _slowArray, _uuid, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend(_testable.default, _labelable.default, {
    selection: null,
    searchText: '',
    show: false,
    allowArbitrary: false,
    displayArbitrary: false,
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    selectOnFocus: true,
    autoSelectFirst: true,
    labelAttribute: Ember.computed.alias('searchText'),
    // considered fresh if they haven't typed anything, so the text input exactly reflects the current value
    fresh: true,
    isLoading: false,
    defaultInputClasses: 'form-control',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('filteredResults', []);

      if (this.initialSearchText && this.initialSearchText.length > 0) {
        this.set('searchText', this.initialSearchText);
      }
    },
    choiceComponentName: Ember.computed('componentName', function () {
      return 'suggest/choices/' + this.componentName;
    }),
    actions: {
      updateSearch: function updateSearch(_, event) {
        if (!(0, _isControlKey.default)(event.which)) {
          (0, _throttle.default)(this, 'updateResultsMatching', this.throttle);

          if (this.allowArbitrary) {
            Ember.tryInvoke(this, 'didChange', [this.searchText]);
          }
        }
      },
      select: function select(result) {
        if (result && !this.validate(result)) {
          return false;
        }

        this.didSelect(result);
        this.set('show', false);
        this.set('selection', null);
        this.set('fresh', true);

        if (result) {
          var label = this.labelForResult(result);
          this.set('searchText', label);
        } else {
          this.set('searchText', null);
        }

        return false;
      },
      add: function add(label) {
        this.handleAddNew(label);
        Ember.tryInvoke(this, 'willAdd', [label]);
      }
    },
    focusIn: function focusIn() {
      var _this = this;

      if (this.initialSearchText && this.initialSearchText.length > 0) {
        this.set('fresh', false);
      } else {
        this.set('fresh', true);
      }

      this.set('show', true);
      this.set('isLoading', true);
      (0, _later.default)(20, function () {
        if (_this.isDestroyed || _this.isDestroying) return;

        if (_this.selectOnFocus) {
          _this.$('input')[0].select();
        }

        _this.set('_previousSearchText', _this.searchText || '');

        _this.updateResultsMatching();
      });
    },
    focusOut: function focusOut() {
      var _this2 = this;

      // allow events to propogate before we clean up
      (0, _later.default)(200, function () {
        if (!_this2.isDestroyed && !_this2.isDestroying) {
          if (_this2.allowArbitrary) {
            Ember.tryInvoke(_this2, 'didChange', [_this2.searchText, true]);
          } else if (!_this2.fresh) {
            // we only want to try and find an exact match if
            // they didn't already select a different one
            _this2.selectIfMatch();
          }

          _this2.set('show', false);

          _this2.set('selection', null);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('show', false);
      this.set('selection', null);
    },
    keyDown: function keyDown(event) {
      var _this3 = this;

      // ignore left and right arrows
      if ([_keycodes.default.ARROW_RIGHT, _keycodes.default.ARROW_LEFT].includes(event.which)) {
        // if they give focus to a select field and arrow to the middle of a word, hitting backspace should not clear
        // the whole thing, hense the arrow keys make this not fresh
        this.set('fresh', false);
        return true;
      }

      if (event.which === _keycodes.default.ARROW_UP) {
        return this.upArrowPressed(event);
      } else if (event.which === _keycodes.default.ARROW_DOWN) {
        return this.downArrowPressed(event);
      } else if (event.which === _keycodes.default.ENTER) {
        return this.enterKeyPressed(event);
      } else if (event.which === _keycodes.default.ESCAPE) {
        return this.escapeKeyPressed(event);
      } else if (event.which === _keycodes.default.BACKSPACE || event.which === _keycodes.default.DELETE) {
        return this.backspaceKeyPressed(event);
      } else if (event.which === _keycodes.default.TAB) {
        return this.tabKeyPressed(event);
      } // if which is undefined, that means the field was auto filled by the browser
      else if (typeof event.which === 'undefined') {
          (0, _later.default)(200, function () {
            _this3.selectIfMatch();
          });
          return true;
        } else {
          return this.unhandledKeyPress(event);
        }
    },
    // overridable key events
    upArrowPressed: function upArrowPressed() {
      Ember.run.throttle(this, 'move', -1, 40);
      return false;
    },
    downArrowPressed: function downArrowPressed() {
      this.set('show', true);
      Ember.run.throttle(this, 'move', +1, 40);
      return false;
    },
    enterKeyPressed: function enterKeyPressed(event) {
      event.preventDefault();
      var selection = this.selection; // add is the only things showing, choose it if they hit enter

      if (this.get('filteredResults.length') === 0 && this.showAdd) {
        this.send('add', this.searchText);
        return false;
      } // if there is only one option, select it


      if (this.get('filteredResults.length') === 1 && !this.allowArbitrary) {
        this.send('select', this.get('filteredResults.firstObject'));
        return false;
      } // if a selection is made, send it


      if (selection) {
        this.send('select', selection);
        return false;
      } // if empty, send null as selection


      if (this.get('searchText.length') === 0) {
        this.send('select', null);
        return false;
      } // otherwise, if there is a match of the current text, select it


      if (this.get('filteredResults.length') === 1) {
        this.selectIfMatch();
        return false;
      }

      event.preventDefault();
    },
    escapeKeyPressed: function escapeKeyPressed() {
      this.clear();
      this.set('show', false);
      this.set('selection', null);
      return true;
    },
    backspaceKeyPressed: function backspaceKeyPressed() {
      this.set('show', true);

      if (this.fresh) {
        this.clear();
        this.didSelect(null);
      }
    },
    tabKeyPressed: function tabKeyPressed() {
      var selection = this.selection; // if there is only one option, select it

      if (this.get('filteredResults.length') === 1 && !this.allowArbitrary) {
        this.send('select', this.get('filteredResults.firstObject'));
      } // if a selection is made, send it
      else if (selection) {
          this.send('select', selection);
        } else {
          return this.selectIfMatch();
        }
    },
    unhandledKeyPress: function unhandledKeyPress() {
      this.set('show', true);
      this.set('fresh', false);
    },
    move: function move(direction) {
      var selection = this.selection;
      var results = this.filteredResults;

      if (!selection) {
        selection = results.get('firstObject');
        this.set('selection', selection);
        this.scrollToSelection();
      } else {
        var idx = results.indexOf(selection);

        if (direction === -1 && idx > 0 || direction === 1 && idx <= results.length) {
          selection = results.objectAt(idx + direction);
          this.set('selection', selection);
          this.scrollToSelection();
        } // when the top row is highlighted, pushing up arrow unhighlights any row so when you tab or enter, it submits
        // exactly what you typed instead of the selection
        else if (direction === -1 && idx === 0) {
            this.set('selection', null);
          }
      }
    },
    scrollToSelection: function scrollToSelection() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var selectedItem = this.$('.autosuggest-item--selected');

        if (selectedItem.length) {
          var parent = this.$('.autosuggest');
          var positionTop = selectedItem.position().top;
          var firstItemTop = parent.find('li:first').position().top;
          var dist = Math.abs(firstItemTop - positionTop);
          var windowMin = parent.scrollTop();
          var windowMax = windowMin + parent.height() - 10;

          if (dist > windowMax) {
            parent.scrollTop(dist - (parent.height() - selectedItem.height()));
          } else if (dist < windowMin) {
            parent.scrollTop(dist);
          }
        }
      });
    },
    _observeInitial: Ember.on('init', Ember.observer('initial', function () {
      var _this4 = this;

      var initial = this.initial;

      if (initial) {
        var show = function show(value) {
          _this4.set('savedInitialValue', value);

          var searchText = _this4.labelForResult(value);

          _this4.set('searchText', _this4.initialSearchText || searchText || '');
        };

        if (initial.then) {
          initial.then(function (result) {
            if (result) {
              show(result);
            } else if (_this4.allowNull) {
              _this4.clear();
            }
          });
        } else {
          show(initial);
        }
      } else if (this.allowNull) {
        this.clear();
      }
    })),
    _observeInitialValue: Ember.on('init', Ember.observer('initialValue', function () {
      var initialValue = this.initialValue;

      if (initialValue && !this.initial && _typeof(initialValue) !== 'object') {
        initialValue = initialValue + '';
        this.set('savedInitialValue', initialValue);
        var label = this.labelForResult(initialValue);

        if (label) {
          this.set('searchText', label);
        } else if (this.allowArbitrary || this.displayArbitrary) {
          this.set('searchText', initialValue);
        }
      }
    })),
    updateResultsMatching: function updateResultsMatching() {
      var _this5 = this;

      Ember.run.next(function () {
        if (_this5.isDestroyed || _this5.isDestroying) return; // we only filter by what they are currently typing. If they blur then focus, don't filter        let searchText = this.searchText || ''
        // by existing text

        var searchText = _this5.fresh ? '' : _this5.searchText || '';

        var results = _this5.resultsMatchingSearchString(searchText && searchText.length ? searchText : null);

        if (results.then) {
          _this5.set('isLoading', true); // in cases where it takes a bit to get results, we want to ignore all responses except for the last one.
          // otherwise, as you type and wait, you get a series of flashing temporary results from old keystrokes that are
          // just now done fetching. So, for each request to update here, we give it a uniq ID and then remove that from
          // the queue of pending requests and only actually update the UI if we're removing the last one.


          var requestID = (0, _uuid.default)();
          var queue = _this5._updateResultsMatchingQueue || [];
          queue.push(requestID);

          _this5.set('_updateResultsMatchingQueue', queue); // if they've added a character, we can actually filter instantly
          // then when the backend responds, it'll just confirm it
          // so, we take the current results, and filter them


          var current = _this5.filteredResults.map(function (r) {
            return {
              result: r,
              label: _this5.labelForResult(r)
            };
          });

          var filtered = current.filter(function (item) {
            return (item.label || '').toLowerCase().indexOf((searchText || '').toLowerCase()) > -1;
          });
          var filteredResults = filtered.map(function (f) {
            return f.result;
          });

          if ((_this5.searchText || '').length > (_this5._previousSearchText || '').length) {
            _this5.set('filteredResults', filteredResults);

            _this5.set('isLoading', false);
          }

          _this5.set('_previousSearchText', _this5.searchText);

          var promise = results.then(function (array) {
            if (_this5.isDestroyed || _this5.isDestroying) return;
            var results = array ? array.toArray().filter(function (i) {
              return !(i.get && i.isNew);
            }) : [];

            var sorted = _this5.sortResults(results, searchText);

            queue.removeObject(requestID);

            if (queue.length === 0) {
              _this5.set('filteredResults', (0, _slowArray.slowNativeArray)(sorted, {
                per: 5
              }));

              _this5.set('isLoading', false);

              if (_this5.autoSelectFirst) {
                _this5.set('selection', sorted.firstObject);
              }
            }

            return sorted;
          });

          _this5.set('searchPromise', _emberData.default.PromiseObject.create({
            promise: promise
          }));
        } else {
          results = results.toArray();

          var sorted = _this5.sortResults(results, searchText);

          _this5.set('filteredResults', (0, _slowArray.slowNativeArray)(sorted, {
            per: 5
          }));

          if (_this5.autoSelectFirst) {
            _this5.set('selection', sorted.firstObject);
          }

          _this5.set('isLoading', false);
        }
      });
    },
    sortResults: function sortResults(results, searchText) {
      var _this6 = this;

      return results.sort(function (a, b) {
        // we auto-highlight the first result. So, when they first enter the component,
        // and it shows all results, we need to sort the current value to the very top
        // so that if they hit enter or tab it will select the current value and not
        // a different value.
        if (_this6.savedInitialValue) {
          if (_this6.savedInitialValue.id && a.id && _this6.savedInitialValue.id === a.id) {
            return -1;
          } else if (_this6.savedInitialValue === a.id || _this6.savedInitialValue === a) {
            return -1;
          } else if (a.id && _this6.savedInitialValue === _this6.labelForResult(a)) {
            return -1;
          } else if (_this6.savedInitialValue.id && b.id && _this6.savedInitialValue.id === b.id) {
            return +1;
          } else if (_this6.savedInitialValue === b.id || _this6.savedInitialValue === b) {
            return +1;
          }
        }

        if (_this6.sort) {
          return _this6.sort(a, b);
        } else {
          return _this6.sortByMatchPosition(a, b, searchText);
        }
      });
    },
    selectionIndex: Ember.computed('selection', 'filteredResults', function () {
      return this.filteredResults.indexOf(this.selection);
    }),
    router: (0, _overridableComputed.default)('router', function () {
      return Ember.getOwner(this).lookup('router:main');
    }),
    sortByMatchPosition: function sortByMatchPosition(a, b, searchText) {
      searchText = searchText.toLowerCase();
      var aLabel = this.labelForResult(a);
      var bLabel = this.labelForResult(b);

      if (aLabel && bLabel) {
        var aIndex = aLabel.toLowerCase().indexOf(searchText);
        var bIndex = bLabel.toLowerCase().indexOf(searchText);
        aIndex = aIndex > -1 ? aIndex : 100000000;
        bIndex = bIndex > -1 ? bIndex : 100000000;
        return aIndex - bIndex;
      }

      return 0;
    },
    hasErrors: (0, _overridableComputed.default)('hasErrors', 'errors.@each', function () {
      return this.get('errors.length') > 0;
    }),
    showAdd: (0, _overridableComputed.default)('showAdd', 'isLoading', 'fresh', function () {
      return this.handleAddNew && !this.preventAdd && !this.fresh && !this.isLoading;
    }),
    trySelect: function trySelect(model) {
      if (!this.isDestroyed && !this.isDestroying) {
        this.send('select', model);
      }
    },
    clear: function clear() {
      this.set('searchText', '');
      this.set('selection', null);
    },
    sanitizedDropdownClass: Ember.computed('dropdownClass', function () {
      return Ember.String.htmlSafe(this.dropdownClass);
    }),
    selectIfMatch: function selectIfMatch() {
      var _this7 = this;

      var selectOnly = function selectOnly(results) {
        if (_this7.isDestroyed || _this7.isDestroying) return;
        var text = _this7.searchText;
        var match = (results || []).find(function (r) {
          return (0, _insensitiveCompare.default)(_this7.labelForResult(r), text);
        });

        if (match) {
          _this7.send('select', match);
        } else if (!_this7.allowArbitrary) {
          _this7.send('select', null);
        }
      };

      if (this.searchPromise && this.searchPromise.then) {
        this.searchPromise.then(selectOnly);
      } else if (this.filteredResults.length > 0) {
        selectOnly(this.filteredResults);
      }
    },
    isSuggestShowing: Ember.computed('show', 'filteredResults.length', function () {
      return this.show && this.filteredResults.length > 0;
    }),
    // override in subclass
    validate: function validate(_selection) {
      return true;
    }
  });

  _exports.default = _default;
});