define("admin/models/contact", ["exports", "@ember-data/model", "admin/models/model", "admin/mixins/taggable", "admin/mixins/actorable", "admin/utils/initials", "admin/helpers/format-phone", "admin/utils/overridable-computed"], function (_exports, _model, _model2, _taggable, _actorable, _initials, _formatPhone, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, _actorable.default, {
    address_1: (0, _model.attr)(),
    address_2: (0, _model.attr)(),
    city: (0, _model.attr)(),
    country: (0, _model.attr)(),
    department: (0, _model.attr)(),
    displayName: (0, _model.attr)(),
    email: (0, _model.attr)(),
    expenses: (0, _model.attr)('money'),
    firstName: (0, _model.attr)(),
    industry: (0, _model.attr)(),
    is1099Required: (0, _model.attr)('boolean'),
    isVisible: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    lastName: (0, _model.attr)(),
    name: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    phone: (0, _model.attr)(),
    postalCode: (0, _model.attr)(),
    revenue: (0, _model.attr)('money'),
    state: (0, _model.attr)(),
    suggestedNextEstimateNumber: (0, _model.attr)(),
    suggestedNextInvoiceNumber: (0, _model.attr)(),
    unbilledJournalEntryLines: (0, _model.attr)('boolean'),
    unbilledTimeEntries: (0, _model.attr)('boolean'),
    website: (0, _model.attr)(),
    integrationObject: (0, _model.belongsTo)('integrationObject'),
    publicProfile: (0, _model.belongsTo)('publicProfile'),
    linkedAccount: (0, _model.belongsTo)('account'),
    estimates: (0, _model.hasMany)('estimate'),
    invoices: (0, _model.hasMany)('invoice'),
    paymentBankAccounts: (0, _model.hasMany)('paymentBankAccount'),
    people: (0, _model.hasMany)('person'),
    projects: (0, _model.hasMany)('project'),
    recurringProfiles: (0, _model.hasMany)('recurringProfile'),
    timeEntries: (0, _model.hasMany)('timeEntry'),
    isCustomer: (0, _overridableComputed.default)('isCustomer', 'revenue', function () {
      return this.revenue > 0;
    }),
    isVendor: (0, _overridableComputed.default)('isVendor', 'revenue', function () {
      return this.expenses > 0;
    }),
    isNotCustomerOrVendor: Ember.computed('isCustomer', 'isVendor', function () {
      return !this.isCustomer && !this.isVendor;
    }),
    isCustomerAndVendor: Ember.computed('isCustomer', 'isVendor', function () {
      return this.isCustomer && this.isVendor;
    }),
    formattedName: Ember.computed('name', 'firstName', 'lastName', 'email', 'phone', 'displayName', function () {
      if (this.firstName && this.lastName) {
        return this.firstName + ' ' + this.lastName;
      } else if (this.name) {
        return this.name;
      } else if (this.firstName || this.lastName) {
        return this.firstName || this.lastName;
      } else if (this.email) {
        return this.email;
      } else if (this.phone) {
        return (0, _formatPhone.formatPhone)([this.phone]);
      } else {
        return this.displayName;
      }
    }),
    initials: Ember.computed('formattedName', function () {
      return (0, _initials.default)(this.formattedName);
    })
  });

  _exports.default = _default;
});