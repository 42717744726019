define("admin/mixins/file-droppable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dragOver: function dragOver() {
      this.set('dragState', 'dragging-over');
      return false;
    },
    dragEnter: function dragEnter() {
      this.set('dragState', 'dragging-over');
      return false;
    },
    dragLeave: function dragLeave() {
      this.set('dragState', 'default');
      return false;
    },
    dragEnd: function dragEnd() {
      this.set('dragState', 'default');
      return false;
    },
    drop: function drop(e) {
      this.set('dragState', 'default');
      var droppedFiles = e.dataTransfer.files;
      this.send('filesDropped', droppedFiles);
      return false;
    }
  });

  _exports.default = _default;
});