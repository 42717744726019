define("admin/controllers/main/super/suspicious-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    actions: {
      suspend: function suspend(account) {
        var _this = this;

        return this.client.send('PATCH', "admin/suspend/".concat(account.id)).then(function () {
          var accounts = $.extend(true, [], _this.model);
          var index = accounts.findIndex(function (e) {
            return e.id === account.id;
          });
          accounts.splice(index, 1);

          _this.set('model', accounts);
        });
      }
    }
  });

  _exports.default = _default;
});