define("admin/components/form-input", ["exports", "admin/mixins/testable", "admin/mixins/labelable", "admin/utils/parse-number", "admin/utils/keycodes"], function (_exports, _testable, _labelable, _parseNumber, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _labelable.default, {
    classNames: 'position-relative',
    attributeBindings: ['highlight:data-highlight'],
    labelAttribute: Ember.computed.alias('valueTransform'),
    hasErrors: Ember.computed('errors.@each', function () {
      return this.get('errors.length') > 0;
    }),
    iconClass: Ember.computed('icon', function () {
      if (this.icon) {
        return 'icon';
      }
    }),
    keyPress: function keyPress(event) {
      if (this.format === 'number') {
        // if the string typed is not a number, hyphen, backspace, or period prevent it
        switch (event.which) {
          case _keycodes.default.BACKSPACE:
          case _keycodes.default.DELETE:
          case _keycodes.default.DASH:
            return;

          default:
            break;
        }

        if (isNaN(String.fromCharCode(event.which))) {
          event.preventDefault();
        }
      }
    },
    focusIn: function focusIn() {
      var _this = this;

      if (this.focusSelect) {
        Ember.run.later(function () {
          _this.$('input')[0].select();
        }, 20);
      }

      if (this.format === 'money' && this.value === 0) {
        this.$('input').val('');
      }
    },
    valueTransform: Ember.computed('value', 'format', {
      get: function get(_key) {
        if (this.format === 'money' && this.value) {
          return (0, _parseNumber.default)(this.value).toFixed(2);
        }

        return this.value;
      },
      set: function set(_key, value) {
        if (this.format === 'money' && value !== '-' && value != '.') {
          this.set('value', (0, _parseNumber.default)(value).round(2));
        } else {
          this.set('value', value);
        }

        return value;
      }
    }),
    focusOut: function focusOut() {
      if (this.format === 'money') {
        this.set('valueTransform', (0, _parseNumber.default)(this.value).round(2, 'string'));
      }

      return true;
    },
    keyDown: function keyDown(event) {
      // always allow backspace/delete
      switch (event.which) {
        case _keycodes.default.BACKSPACE:
        case _keycodes.default.DELETE:
          return;

        default:
          break;
      }

      if (this.prevent && this.prevent.split('').find(function (c) {
        return c === event.key;
      }) && event.target.value === '') {
        return false;
      }

      if (this.allow && !this.allow.split('').find(function (c) {
        return c === event.key;
      })) {
        return false;
      }

      if (this.validationAction && !this.validationAction(event.target.value)) {
        return false;
      }
    },
    filteredErrors: Ember.computed('errors.@each', function () {
      var _this2 = this;

      if (this.get('errors.length') && this.valuesError) {
        return this.errors.firstValue(function (error) {
          if (Ember.typeOf(error.message) === 'object' && error.message[_this2.valuesError]) {
            return error.message[_this2.valuesError].map(function (error) {
              return {
                message: error
              };
            });
          }
        }) || [];
      } else {
        return this.errors;
      }
    })
  });

  _exports.default = _default;
});