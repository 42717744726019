define("admin/models/reconciliation", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    endBalance: (0, _model.attr)(),
    endDate: (0, _model.attr)(),
    isReconciled: (0, _model.attr)('boolean'),
    startDate: (0, _model.attr)()
  });

  _exports.default = _default;
});