define("admin/models/imported-transaction", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    amount: (0, _model.attr)('money'),
    category: (0, _model.attr)(),
    currency_code: (0, _model.attr)('string', {
      example: 'USD'
    }),
    date: (0, _model.attr)('date'),
    external_id: (0, _model.attr)(),
    memo: (0, _model.attr)(),
    journalEntryLine: (0, _model.belongsTo)('journalEntryLine'),
    chartAccount: (0, _model.belongsTo)('chartAccount')
  });

  _exports.default = _default;
});