define("admin/templates/main/super/devops/jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8LiizR3O",
    "block": "{\"symbols\":[\"q\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"fadeInFast\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"box box-space mt-4\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"box-space-header\"],[8],[0,\"Queues\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"data\",\"queues\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"row cursor-pointer\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"col-12 col-sm-3 col-lg-3\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"col-12 col-sm-1 col-lg-1\"],[8],[1,[23,1,[\"job-count\"]],false],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"col-12 col-sm-1 col-lg-1\"],[8],[7,\"button\",false],[12,\"class\",\"btn btn-tiny btn-blue btn-wide btn-inline\"],[3,\"action\",[[23,0,[]],\"generateReport\",[23,1,[\"name\"]]]],[8],[0,\"Generate report\"],[9],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/main/super/devops/jobs.hbs"
    }
  });

  _exports.default = _default;
});