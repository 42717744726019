define("admin/helpers/current-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentDate = currentDate;
  _exports.default = void 0;

  function currentDate()
  /*params, hash*/
  {
    return new Date();
  }

  var _default = Ember.Helper.helper(currentDate);

  _exports.default = _default;
});