define("admin/mixins/patch-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var alwaysSend = this.getWithDefault('alwaysSend', []).includes(key);
      var isNew = snapshot.record.get('isNew');
      var didChange = snapshot.changedAttributes()[key];

      if (alwaysSend || isNew || didChange) {
        this._super(snapshot, json, key, attributes);
      }
    }
  });

  _exports.default = _default;
});