define("admin/serializers/application", ["exports", "@ember-data/serializer/json", "@ember-data/serializer/json-api", "@ember-data/adapter/error"], function (_exports, _json, _jsonApi, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _jsonApi.default.extend({
    extractErrors: function extractErrors(_store, typeClass, payload
    /*, id*/
    ) {
      var _this = this;

      if (payload && _typeof(payload) === 'object' && payload.errors) {
        payload = (0, _error.errorsArrayToHash)(payload.errors);
        this.normalizeUsingDeclaredMapping(typeClass, payload);
        typeClass.eachAttribute(function (name) {
          var key = _this.keyForAttribute(name, 'deserialize');

          name = name.camelize();

          if (key !== name && payload[key] !== undefined) {
            payload[name] = payload[key];
            delete payload[key];
          }
        });
        typeClass.eachRelationship(function (name) {
          var key = _this.keyForRelationship(name, 'deserialize');

          name = name.camelize();

          if (key !== name && payload[key] !== undefined) {
            payload[name] = payload[key];
            delete payload[key];
          }
        });
      }

      return payload;
    },
    extractAttributes: function extractAttributes(_modelClass, resourceHash) {
      var attributes = this._super.apply(this, arguments);

      if (resourceHash.type === 'tag') {
        attributes.taggableType = Ember.get(resourceHash, 'relationships.taggable.data.type');
      }

      return attributes;
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return modelName;
    },
    keyForAttribute: function keyForAttribute(attr, method) {
      if (attr === 'is1099Required') {
        attr = 'is-1099-required';
      }

      return this._super(attr, method);
    },
    // silences warning: "The JSONAPISerializer does not work with the EmbeddedRecordsMixin because the JSON API spec does not describe how to format embedded resources."
    willMergeMixin: function willMergeMixin(props) {
      var constructor = this.constructor;
      (false && Ember.warn("You've defined 'extractMeta' in ".concat(constructor.toString(), " which is not used for serializers extending JSONAPISerializer. Read more at http://emberjs.com/api/data/classes/DS.JSONAPISerializer.html#toc_customizing-meta on how to customize meta when using JSON API."), Ember.isNone(props.extractMeta) || props.extractMeta === _json.default.prototype.extractMeta, {
        id: 'ds.serializer.json-api.extractMeta'
      }));
    },
    normalizeQueryResponse: function normalizeQueryResponse(_store, _class, payload) {
      var result = this._super.apply(this, arguments);

      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.links = payload.links;
      }

      return result;
    }
  });

  _exports.default = _default;
});