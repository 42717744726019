define("admin/mixins/transaction-list", ["exports", "admin/mixins/list-controller-transactionable", "admin/utils/refresh-chart-accounts", "zipbooks/utils/pluralize", "zipbooks/utils/push-payload", "zipbooks/utils/classifications"], function (_exports, _listControllerTransactionable, _refreshChartAccounts, _pluralize, _pushPayload, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_listControllerTransactionable.default, {
    notificationCenter: Ember.inject.service(),
    selectedAreAllConfirmed: Ember.computed('selected.@each.isConfirmed', function () {
      if (this.modelType === 'journal_entry') {
        return this.selected.every(function (je) {
          return je.get('isConfirmed');
        });
      } else {
        return this.selected.every(function (l) {
          return l.get('journalEntry.isConfirmed');
        });
      }
    }),
    actions: {
      filterByChartAccount: function filterByChartAccount(chartAccount) {
        this.transitionToRoute({
          queryParams: {
            chart_account_id: chartAccount && chartAccount.id,
            page: 1
          }
        });
      },
      dateFiltered: function dateFiltered() {
        this.set('page', 1);
      },
      match: function match() {
        var _this = this;

        var jes = this.selectedJournalEntries();

        if (jes.length === 2) {
          this.match(jes[0], jes[1], function () {
            jes[0].unloadRecord();
            jes[1].unloadRecord();

            _this.refresh();

            (0, _refreshChartAccounts.default)(_this.store, jes);
          });
        }
      },
      bulkConfirm: function bulkConfirm() {
        var _this2 = this;

        var jes = this.selectedJournalEntries();
        this.bulkConfirm(jes, function (results) {
          if (results.succeeded.length > 0) {
            (0, _refreshChartAccounts.default)(_this2.store, jes);

            _this2.refreshMeta();
          }
        });
      },
      bulkUnconfirm: function bulkUnconfirm() {
        var _this3 = this;

        var jes = this.selectedJournalEntries();
        this.bulkUnconfirm(jes, function (results) {
          if (results.succeeded.length > 0) {
            (0, _refreshChartAccounts.default)(_this3.store, results.succeeded);

            _this3.refresh();

            _this3.refreshMeta();
          }
        });
      },
      bulkDelete: function bulkDelete() {
        var _this4 = this;

        var jes = this.selectedJournalEntries();
        this.bulkDelete(jes, function (results) {
          if (results.succeeded.length > 0) {
            (0, _refreshChartAccounts.default)(_this4.store, results.succeeded);

            _this4.refresh();

            _this4.refreshMeta();
          }
        });
      },
      bulkCategorize: function bulkCategorize(chartAccount) {
        var _this5 = this;

        var selected = this.selected;

        if (selected.length > 0 && chartAccount && chartAccount.get('id')) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: "Are you sure you want to categorize ".concat(selected.get('length'), " transactions as ").concat(chartAccount.get('name'), "?"),
            confirmButton: {
              title: 'Yes, Categorize',
              class: 'btn-blue'
            },
            cancelButton: {
              title: 'No, cancel',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              var lines = _this5.selectedLinesToCategorize();

              if (lines.length > 0) {
                _this5.batch(lines, function (model) {
                  model.set('chartAccount', chartAccount);
                  model.set('journalEntry.autoConfirm', true);

                  var json = _this5.store.peekRecord('journalEntry', model.get('journalEntry.id')).serialize({
                    includeId: true
                  });

                  json.data.attributes['batch-item-id'] = model.id;
                  return json;
                }, function (results) {
                  if (results.succeeded.length > 0) {
                    _this5.refresh();

                    _this5.unselectAll();

                    (0, _refreshChartAccounts.default)(_this5.store, lines.map(function (l) {
                      return l.get('journalEntry');
                    }).uniq());
                  }

                  _this5.showBulkBanner('categorized', results.succeeded.length, results.unchanged.length, results.failed.length);
                });
              }
            },
            cancel: function cancel() {}
          });
        }
      },
      bulkTag: function bulkTag(tag) {
        var _this6 = this;

        var selected = this.selected;

        if (selected.length > 0 && tag && tag.id) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: "Are you sure you want to tag ".concat(selected.get('length'), " transactions with ").concat(tag.name, "?"),
            confirmButton: {
              title: 'Yes',
              class: 'btn-blue'
            },
            cancelButton: {
              title: 'No, cancel',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              var ids = _this6.selectedJournalEntryLines().mapBy('id');

              _this6.client.send('POST', 'tags', null, {
                data: {
                  type: 'tag',
                  attributes: {
                    name: tag.name
                  },
                  relationships: {
                    journal_entry_lines: {
                      data: ids.map(function (id) {
                        return {
                          id: id,
                          type: 'journal-entry-line'
                        };
                      })
                    },
                    taggable: {
                      data: {
                        id: tag.id,
                        type: tag.type
                      }
                    }
                  }
                }
              }).then(function (resp) {
                (0, _pushPayload.default)(_this6.store, 'tag', resp);

                _this6.refresh();

                _this6.unselectAll();

                _this6.showBulkBanner('tagged', selected.length);
              }, function (jqXHR
              /*, textStatus, errorThrown*/
              ) {
                _this6.unselectAll();

                var error = Ember.getWithDefault(jqXHR, 'responseJSON.errors', [{
                  title: 'Tagging the transaction(/s) failed.'
                }]);

                _this6.overlay.showBanner('error', (0, _pluralize.default)(error[0].title, selected.length));
              });
            },
            cancel: function cancel() {}
          });
        }
      },
      askClient: function askClient() {
        var _this7 = this;

        this.set('askClientButtonState', 'saving');
        var lineIds = this.selectedJournalEntryLines().mapBy('id');

        if (lineIds && lineIds.length > 0) {
          var body = {
            data: {
              type: 'label',
              attributes: {
                name: moment().format('MMM D h:mma'),
                kind: 'Message'
              },
              relationships: {
                tags: {
                  data: lineIds.map(function (id) {
                    return {
                      type: 'tag',
                      relationships: {
                        'tagged-journal-entry-line': {
                          data: {
                            id: id,
                            type: 'journal-entry-line'
                          }
                        }
                      }
                    };
                  })
                }
              }
            }
          };
          return this.client.POST('labels', null, body).then(function (payload) {
            var label = (0, _pushPayload.default)(_this7.store, 'label', payload);
            return _this7.store.createRecord('token', {
              tokenable: label
            }).save().then(function (token) {
              _this7.set('askClientButtonState', 'loaded');

              _this7.overlay.showPopout('messaging/accountant-messenger', {
                suggestedMessage: {
                  label: label,
                  link: "".concat(window.location.origin, "/m/").concat(token.token)
                }
              });
            }, function () {
              _this7.set('askClientButtonState', 'invalid');
            });
          }, function () {
            _this7.set('askClientButtonState', 'invalid');
          });
        } else {
          this.set('askClientButtonState', 'invalid');
        }
      }
    },
    filteredChartAccount: Ember.computed('chart_account_id', function () {
      var cid = this.chart_account_id;

      if (cid === 'all') {
        return Ember.Object.create({
          id: 'all',
          name: 'All',
          modelType: 'chartAccount'
        });
      } else {
        var filterClasses = (0, _classifications.filterClassifications)();
        var found = filterClasses[cid];

        if (found) {
          return Ember.Object.create({
            id: found.id,
            name: found.name,
            modelType: 'chartAccount'
          });
        } else {
          return this.get('model.chartAccounts').filterBy('id', this.get('chart_account_id')).get('firstObject');
        }
      }
    }),
    selectedJournalEntries: function selectedJournalEntries() {
      var _this8 = this;

      var entries;

      if (this.modelType === 'journal_entry') {
        entries = this.selected;
      } else {
        entries = this.selected.mapBy('journalEntry');
      }

      return entries.filter(function (je) {
        return !!je.get('id');
      }).uniqBy('id').mapBy('id').map(function (id) {
        return _this8.store.peekRecord('journalEntry', id);
      });
    },
    selectedJournalEntryLines: function selectedJournalEntryLines() {
      return this.selectedJournalEntries().reduce(function (a, l) {
        return a.concat(l.get('journalEntryLines').toArray());
      }, []).filter(function (jel) {
        return !!jel.get('id');
      }).uniqBy('id');
    },
    // always flipped except when filtering by all-banks and critical accounts
    isFlipped: Ember.computed('chart_account_id', function () {
      // if filtered by an all- filter that isn't all-banks
      if ((this.get('chart_account_id') || '').indexOf('all-') > -1 && this.get('chart_account_id') !== 'all-banks') {
        return true;
      } // if filtered by any chart account that isn't critical


      var chartAccount = this.store.peekRecord('chartAccount', this.get('chart_account_id') || 0);

      if (chartAccount) {
        return !chartAccount.get('classificationInfo').critical;
      }

      return false;
    }),
    // flip amount color (green/red) for a specific set of journal entries following jarens rules
    // https://github.com/ZipBooks/zipbooks/issues/405
    equityException: Ember.computed.empty('chart_account_id'),
    // use only for bulk categorize
    selectedLinesToCategorize: function selectedLinesToCategorize() {
      var selected = this.selected;

      if (this.modelType === 'journal_entry') {
        return selected.map(function (l) {
          if (l.get('primaryLine.balancingLines.length') === 1) {
            return l.get('primaryLine.balancingLines.firstObject');
          }
        }).compact().filter(function (jel) {
          return !!jel.get('id');
        });
      } else {
        if (this.isFlipped) {
          return selected;
        } else {
          return selected.map(function (l) {
            if (l.get('balancingLines.length') === 1) {
              return l.get('balancingLines.firstObject');
            }
          }).compact().filter(function (jel) {
            return !!jel.get('id');
          });
        }
      }
    },
    journalEntriesFromIds: function journalEntriesFromIds(ids) {
      var _this9 = this;

      return ids.map(function (id) {
        return _this9.store.peekRecord('journalEntry', id);
      }).compact();
    }
  });

  _exports.default = _default;
});