define("admin/models/plan", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    privileges: (0, _model.attr)(),
    externalId: (0, _model.attr)(),
    extra: (0, _model.attr)(),
    flags: (0, _model.attr)()
  });

  _exports.default = _default;
});