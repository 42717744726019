define("admin/mixins/recipientable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    recipients: (0, _model.hasMany)('message-recipient', {
      inverse: null
    })
  });

  _exports.default = _default;
});