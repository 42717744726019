define("admin/helpers/linkable-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkableUrl = linkableUrl;
  _exports.default = void 0;

  function linkableUrl(params
  /*, hash*/
  ) {
    var url = params[0];

    if (['http://', 'https://'].any(function (scheme) {
      return url.substr(0, scheme.length) === scheme;
    })) {
      return url;
    } else {
      return 'http://' + url;
    }
  }

  var _default = Ember.Helper.helper(linkableUrl);

  _exports.default = _default;
});