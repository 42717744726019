define("admin/helpers/search-highlight", ["exports", "admin/helpers/truncate"], function (_exports, _truncate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchHighlight = searchHighlight;
  _exports.default = void 0;

  function searchHighlight(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var text = params[0];
    var term = params[1];
    var regex = RegExp(term, 'gi');
    var array;

    if (!text || !term) {
      return text;
    }

    text = text.stripHTML(); // get all the location of the term in the whole text

    var locations = [];
    var count = 0;

    while ((array = regex.exec(text)) !== null) {
      if (count++ > 10) {
        return;
      }

      locations.push(array.index);
    }

    var excerptLength = 500; // if there is a max length (truncate), the excerpt legnth should be shrunk for each location so
    // that it'll all fit

    if (hash.truncate) {
      excerptLength = Math.floor(hash.truncate / locations.length);
    } // if there are no matches, just return the original string


    if (locations.length === 0) {
      if (hash.truncate) {
        return (0, _truncate.truncate)([text, hash.truncate, true]);
      }

      return text;
    } // how many characters should we show on each side of each location?


    var radius = Math.floor((excerptLength - term.length) / 2.0);
    var elipses = '...'; // for each character, filter out the ones that are not within a radius to the match

    var characters = text.split('').map(function (c, idx) {
      if (locations.any(function (loc) {
        return Math.abs(loc - idx) < radius;
      })) {
        return c;
      } else {
        return elipses;
      }
    }) // coalesce the removed characters to one elipses
    .filter(function (item, pos, arr) {
      return pos === 0 || item !== elipses || item !== arr[pos - 1];
    }); // wrap all location with html so it can be styled to stand out

    var wrapper = hash.wrapper || '<span class="search-highlight">{{string}}</span>';
    var final = characters.join('').replace(regex, function (term) {
      return wrapper.replace(/\{\{(.*?)\}\}/g, function (_match, _i) {
        return term;
      });
    });
    return Ember.String.htmlSafe(final);
  }

  var _default = Ember.Helper.helper(searchHighlight);

  _exports.default = _default;
});