define("admin/components/reports/line-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mouseEnter: function mouseEnter() {
      $(".account-name-".concat(this.identifier)).addClass('report-line-hover');
    },
    mouseLeave: function mouseLeave() {
      $(".account-name-".concat(this.identifier)).removeClass('report-line-hover');
    }
  });

  _exports.default = _default;
});