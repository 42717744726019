define("admin/models/journal-entry-line", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amount: (0, _model.attr)('money', {
      defaultValue: 0
    }),
    date: (0, _model.attr)('date'),
    importedMemo: (0, _model.attr)(),
    is1099Required: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    kind: (0, _model.attr)(),
    name: (0, _model.attr)(),
    billToContact: (0, _model.belongsTo)('contact'),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    importedTransaction: (0, _model.belongsTo)('importedTransaction'),
    journalEntry: (0, _model.belongsTo)('journalEntry'),
    tags: (0, _model.hasMany)('tag'),
    // all lines other than the primary lines
    otherLines: Ember.computed.filter('journalEntry.journalEntryLines.@each', function (line
    /*, index, array*/
    ) {
      return line.get('id') !== this.id || line !== this;
    }),
    // all lines with the same kind (appear at the top with the primary line)
    complementaryLines: Ember.computed.filter('otherLines.@each.kind', function (line
    /*, index, array*/
    ) {
      return line.get('kind') === this.kind;
    }),
    // lines with opposite kind, appear below on split transaction screen
    balancingLines: Ember.computed.filter('otherLines.@each.kind', function (line
    /*, index, array*/
    ) {
      return line.get('kind') !== this.kind;
    }),
    // opposite kind. debit => credit, credit => debit
    balancingKind: Ember.computed('kind', function () {
      return this.kind === 'debit' ? 'credit' : 'debit';
    }),
    computedTags: Ember.computed('tags.@each.name', 'tags.@each.type', function () {
      return this.tags;
    }),
    tagCount: Ember.computed.alias('computedTags.length'),

    /************************
     * Auto Categorization
     *************************/
    session: Ember.inject.service(),
    adjustedConfidence: 0.7,
    chartAccountSuggestion: Ember.computed('journalEntry.isConfirmed', 'suggestedChartAccount.id', 'chartAccount.id', function () {
      var unconfirmed = !this.get('journalEntry.isConfirmed');
      var uncategorized = !this.get('chartAccount.id');
      var hasSuggestion = !!this.get('suggestedChartAccount.id');
      var isPrivileged = this.session.hasPrivilege('auto-categorize');
      var confidentEnough = this.suggestedChartAccountConfidence > 80;

      if (unconfirmed && uncategorized && hasSuggestion && isPrivileged && confidentEnough) {
        return this.suggestedChartAccount;
      }
    }),
    cascadeChartAccountSuggestion: Ember.computed('journalEntry.isConfirmed', 'suggestedChartAccount.id', 'chartAccount.id', 'suggestedChartAccountMethod', function () {
      var unconfirmed = !this.get('journalEntry.isConfirmed');
      var uncategorized = !this.get('chartAccount.id');
      var hasSuggestion = !!this.get('suggestedChartAccount.id');
      var isCascade = this.suggestedChartAccountMethod === 'cascade';
      var isPrivileged = this.session.hasPrivilege('auto-categorize');

      if (unconfirmed && uncategorized && hasSuggestion && isCascade && isPrivileged) {
        return this.suggestedChartAccount;
      }
    }),
    suggestedChartAccountConfidence: (0, _model.attr)('confidence'),
    suggestedChartAccountMethod: (0, _model.attr)(),
    suggestedChartAccount: (0, _model.belongsTo)('chartAccount'),
    adjustedSuggestedChartAccountConfidence: Ember.computed('suggestedChartAccountConfidence', 'suggestedChartAccountMethod', function () {
      if (this.suggestedChartAccountMethod === 'general') {
        return (this.suggestedChartAccountConfidence * this.adjustedConfidence).round(0);
      }

      return this.suggestedChartAccountConfidence;
    }),
    categorizeWithSuggestionByDefault: function categorizeWithSuggestionByDefault() {
      var suggestion = this.get('chartAccountSuggestion.id') || this.get('cascadeChartAccountSuggestion.id');

      if (suggestion && !this.get('chartAccount.id')) {
        this.set('chartAccount', this.store.peekRecord('chartAccount', suggestion));
      }
    },
    // used to access two levels after each in computed property.
    // After saving an uncreated chart account, the id of the new chart account
    // is returned with no other data. This causes computed properties that need
    // data like classification to fail. Ember automatically loads the chart account
    // shortly after, but we need to observe the chart account name in order to
    // reload computed properties when the full data is availabe. This alias makes it possible
    // to observer the chart account data population on journal entry lines in arrays
    chartAccountName: Ember.computed.alias('chartAccount.name'),
    // when we receive saved lines back from the server, the best we can do right now is to match them up with
    // the original pre-saved lines by all the properties. If there are two lines that are identical, then, in theory,
    // it won't matter at all if we swap them. So, if two lines have the same `identityKey` they are interchangable and
    // identical
    identityKey: function identityKey() {
      return [this.name, this.amount, moment(this.date).format('YYYY-MM-DD'), this.kind, this.importedMemo, this.is1099Required, this.get('chartAccount.name'), this.get('billToContact.formattedName'), this.getWithDefault('tags', []).map(function (t) {
        return t.name;
      }).join(',')].join('--');
    }
  });

  _exports.default = _default;
});