define("admin/templates/components/avatar-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DWE8EFrt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loadImage\"]]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[\"valid\",[28,\"action\",[[23,0,[]],\"badUrl\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"rounded-image rounded-image-\",[22,\"size\"],\" \",[28,\"if\",[[24,[\"white\"]],\"rounded-image-white\"],null],\" \",[22,\"css\"]]]],[11,\"style\",[28,\"if\",[[24,[\"color\"]],[28,\"safe\",[[28,\"concat\",[\"border: 3px solid \",[24,[\"color\"]]],null]],null]],null]],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[22,\"url\"]],[10,\"width\",\"50\"],[11,\"onerror\",[28,\"action\",[[23,0,[]],\"badUrl\"],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[\"missing\",[28,\"action\",[[23,0,[]],\"badUrl\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"rounded-image rounded-image-\",[22,\"size\"],\" rounded-image-placeholder \",[22,\"css\"]]]],[11,\"style\",[28,\"if\",[[24,[\"color\"]],[28,\"safe\",[[28,\"concat\",[\"background-color: \",[24,[\"color\"]]],null]],null]],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"initials\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"concat\",[[24,[\"initials\"]],\"\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/avatar-image.hbs"
    }
  });

  _exports.default = _default;
});