define("admin/helpers/is-past", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPast = isPast;
  _exports.default = void 0;

  function isPast(params
  /*, hash*/
  ) {
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    return moment(params[0]).isBefore(now);
  }

  var _default = Ember.Helper.helper(isPast);

  _exports.default = _default;
});