define("admin/helpers/customers-called", ["exports", "admin/utils/format-called"], function (_exports, _formatCalled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    onUpdate: Ember.observer('session.account.settings.customersCalled', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      return (0, _formatCalled.default)(this.get('session.account.settings.customersCalled') || 'customers', params);
    }
  });

  _exports.default = _default;
});