define("admin/templates/components/dropdown-item-multi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIjGOs+a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"dropdown-item-multi-check-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"circle-loader\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"i\",true],[11,\"class\",[28,\"if\",[[24,[\"loading\"]],\"\",[28,\"if\",[[28,\"param-exists\",[[24,[\"param\"]],[24,[\"paramCurrentWhen\"]]],null],\"icon-check-circle blue\"],null]],null]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/dropdown-item-multi.hbs"
    }
  });

  _exports.default = _default;
});