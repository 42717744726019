define("admin/models/tax", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    rate: (0, _model.attr)('decimal-2'),
    integrationObject: (0, _model.belongsTo)('integrationObject')
  });

  _exports.default = _default;
});