define("admin/mixins/line-itemable-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    overlay: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.isNew) {
        controller.set('editing', true);

        if (!model.currencyCode) {
          model.set('currencyCode', model.get('account.defaultCurrencyCode'));
        }
      } else {
        // assign tax ids as unique keys in a map. If each map only has one key,
        // we know the taxes were the same for all line items
        var firstTaxes = {};
        var secondTaxes = {};
        model.lineItems.forEach(function (lineItem) {
          firstTaxes[lineItem.get('taxes.firstObject.id')] = 1;
          secondTaxes[lineItem.get('taxes.lastObject.id')] = 1;
        });
        model.set('syncTaxes', Object.keys(firstTaxes).length <= 1 && Object.keys(secondTaxes).length <= 1);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.controller.get('editing') && !this.confirmedExit) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: 'You have unsaved changes. Are you sure you want to leave?',
            confirmButton: {
              title: "Yes, I'm done",
              class: 'btn-blue'
            },
            cancelButton: {
              title: 'No, stay here',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              _this.set('confirmedExit', true);

              transition.retry();
            },
            cancel: function cancel() {}
          });
          transition.abort();
        } else {
          this.set('confirmedExit', false); // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.

          return true;
        }
      }
    },
    resetController: function resetController(controller) {
      controller.set('model', null);
    }
  });

  _exports.default = _default;
});