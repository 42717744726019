define("admin/mixins/line-itemable", ["exports", "@ember-data/model", "admin/mixins/notificationable", "admin/mixins/cloud-fileable", "admin/utils/parse-number", "admin/mixins/taggable", "admin/utils/to-decimal", "admin/utils/base-64"], function (_exports, _model, _notificationable, _cloudFileable, _parseNumber, _taggable, _toDecimal, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_notificationable.default, _cloudFileable.default, _taggable.default, {
    title: (0, _model.attr)(),
    poNumber: (0, _model.attr)(),
    currencyCode: (0, _model.attr)('string', {
      example: 'USD'
    }),
    notes: (0, _model.attr)(),
    terms: (0, _model.attr)(),
    lineItems: (0, _model.hasMany)('lineItem'),
    account: (0, _model.belongsTo)('account'),
    contact: (0, _model.belongsTo)('contact'),
    recurringProfile: (0, _model.attr)(),
    history: (0, _model.attr)(),
    paymentProcessor: (0, _model.attr)(),
    logoCloudFile: (0, _model.belongsTo)('cloudFile'),
    taskLineItems: Ember.computed.filter('lineItems.@each.id', function (lineItem) {
      return lineItem.type === 'time_entry';
    }),
    itemLineItems: Ember.computed.filter('lineItems.@each.id', function (lineItem) {
      return lineItem.get('type') === 'item';
    }),
    activeLineItems: Ember.computed.filter('lineItems.@each.{startDate,endDate}', function (lineItem) {
      return lineItem.active;
    }),
    // provided by API
    total: (0, _model.attr)('money'),
    subTotal: (0, _model.attr)('money'),
    // computed live for editing line itemables
    computedSubtotal: Ember.computed('activeLineItems.@each.total', function () {
      return this.activeLineItems.reduce(function (acc, li) {
        return acc.plus(li.total);
      }, (0, _toDecimal.default)(0));
    }),
    computedDiscountAmount: Ember.computed('activeLineItems.@each.discountTotal', function () {
      return this.activeLineItems.reduce(function (acc, li) {
        return acc.plus(li.discountTotal);
      }, (0, _toDecimal.default)(0));
    }),
    computedTaxTotals: Ember.computed('activeLineItems.@each.tax1', 'activeLineItems.@each.tax2', 'activeLineItems.@each.discountTotal', function () {
      var taxes = {};
      this.activeLineItems.forEach(function (lineItem) {
        ;
        [0, 1].forEach(function (index) {
          var tax = lineItem.get("taxes.".concat(index));

          if (tax && tax.rate && tax.rate.length > 0) {
            // base64 encode the key because we were having trouble with some characters (periods) in the keys
            // causing it to break the calculation
            var key = (0, _base.encode)(tax.name);

            if (!taxes[key]) {
              taxes[key] = {
                name: tax.name,
                rate: tax.rate,
                total: (0, _toDecimal.default)(0)
              };
            }

            var total = lineItem.total;
            var discount = lineItem.discountTotal;
            var rate = (0, _toDecimal.default)((0, _parseNumber.default)(tax.rate)).times(0.01);
            var final = total.minus(discount).times(rate).toDecimalPlaces(2);
            taxes[key].total = taxes[key].total.plus(final);
          }
        });
      });
      return taxes;
    }),
    computedTaxTotal: Ember.computed('computedTaxTotals', function () {
      var totals = this.computedTaxTotals;
      return Object.keys(totals).reduce(function (acc, key) {
        return acc.plus(totals[key].total);
      }, (0, _toDecimal.default)(0));
    }),
    computedTotal: Ember.computed('computedSubtotal', 'computedDiscountAmount', 'computedTaxTotal', function () {
      var subtotal = this.computedSubtotal;
      var discount = this.computedDiscountAmount;
      var taxes = this.computedTaxTotal;
      return subtotal.minus(discount).plus(taxes);
    }),
    computedAmountDue: Ember.computed('computedTotal', 'paidTotal', function () {
      var paid = (0, _toDecimal.default)((0, _parseNumber.default)(this.paidTotal));
      return this.computedTotal.minus(paid);
    })
  });

  _exports.default = _default;
});