define("admin/helpers/format-truthy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTruthy = formatTruthy;
  _exports.default = void 0;

  function formatTruthy(params
  /*, hash*/
  ) {
    return params[0] ? 'Yes' : 'No';
  }

  var _default = Ember.Helper.helper(formatTruthy);

  _exports.default = _default;
});