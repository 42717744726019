define("admin/models/subscription", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    status: (0, _model.attr)(),
    trialEndsOn: (0, _model.attr)('date'),
    nextBillingDate: (0, _model.attr)('date'),
    quantity: (0, _model.attr)(),
    planPostfix: (0, _model.attr)(),
    plan: (0, _model.belongsTo)('plan'),
    flags: (0, _model.attr)(),
    isPaid: Ember.computed('plan.name', function () {
      return ['Smarter', 'Sophisticated'].includes(this.get('plan.name'));
    }),
    isTrialing: Ember.computed.equal('status', 'trialing'),
    isActive: Ember.computed('status', 'nextBillingDate', 'canceledAt', function () {
      return ['active', 'trialing'].includes(this.status) && (!this.canceledAt || moment(this.nextBillingDate).isAfter(moment()));
    }),
    isCanceled: Ember.computed('canceledAt', function () {
      return !!this.canceledAt;
    }),
    isOverdue: Ember.computed.equal('status', 'overdue'),
    createdAt: (0, _model.attr)('datetime'),
    canceledAt: (0, _model.attr)('datetime')
  });

  _exports.default = _default;
});