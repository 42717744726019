define("admin/utils/secure-downloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = secureDownloader;

  function secureDownloader(cloudFile) {
    // if its already an s3 url, we'll just download it. It probably shouldn't be,
    // but I'd rather it still work…
    var client = Ember.getOwner(cloudFile).lookup('service:client');
    var downloadUrl = cloudFile.get('downloadUrl'); // if its a private url, use it to get the temporary s3 url

    if (downloadUrl.indexOf('v2/cloud-files') > -1) {
      client.GET(downloadUrl).then(function (resp) {
        window.open(resp['download-url'], '_blank');
      });
    } // otherwise, if its an s3 url or external url
    else {
        window.open(downloadUrl, '_blank');
      }
  }
});