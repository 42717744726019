define("admin/helpers/middle-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.middleEllipsis = middleEllipsis;
  _exports.default = void 0;

  function middleEllipsis(params
  /*, hash*/
  ) {
    var str = params[0];

    if (str && str.length > 25) {
      return str.substr(0, 12) + '...' + str.substr(str.length - 7, str.length);
    }

    return str;
  }

  var _default = Ember.Helper.helper(middleEllipsis);

  _exports.default = _default;
});