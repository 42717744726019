define("admin/overrides/number", ["admin/utils/to-decimal"], function (_toDecimal) {
  "use strict";

  // javascript toFixed and Math.round round 5 down (0.005 -> 0.00), so we do
  // this nonsense here to fix that
  Number.prototype.round = function (decimals) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'number';
    var val = (0, _toDecimal.default)(this).toFixed(decimals);

    if (type === 'number') {
      return val * 1;
    }

    return val;
  };

  Number.prototype.pad = function (size) {
    var s = String(this);

    while (s.length < (size || 2)) {
      s = '0' + s;
    }

    return s;
  };
});