define("admin/helpers/score-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scoreColor = scoreColor;
  _exports.default = void 0;

  function scoreColor(params
  /*, hash*/
  ) {
    var score = params[0];

    if (score === null) {
      return '';
    } else if (score <= 50) {
      return 'red';
    } else if (score <= 75) {
      return 'gold';
    } else {
      return 'green';
    }
  }

  var _default = Ember.Helper.helper(scoreColor);

  _exports.default = _default;
});