define("admin/serializers/user", ["exports", "admin/serializers/application", "admin/mixins/embedded-jsonapi-records"], function (_exports, _application, _embeddedJsonapiRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_embeddedJsonapiRecords.default, {
    attrs: {
      account: {
        deserialize: 'records'
      },
      identity: {
        serialize: 'records'
      },
      integrationObject: {
        serialize: 'records'
      },
      notifications: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});