define("admin/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8qxKUmH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"class\",\"autocomplete\",\"name\",\"disabled\",\"placeholder\",\"title\",\"testId\",\"readonly\"],[[24,[\"stringValue\"]],[28,\"concat\",[[28,\"concat\",[\"date-picker form-control \",[24,[\"inputClass\"]],\" \",[24,[\"positionClass\"]],[28,\"if\",[[24,[\"disabled\"]],\" disabled\"],null]],null],\" \",[28,\"if\",[[24,[\"hasErrors\"]],\"input-error\"],null],\" \"],null],\"off\",\"date\",[24,[\"disabled\"]],[24,[\"placeholder\"]],[24,[\"title\"]],[24,[\"testId\"]],[24,[\"readonly\"]]]]],false],[0,\"\\n\"],[7,\"label\",true],[11,\"class\",[29,[\"label-inside \",[28,\"unless\",[[24,[\"animateLabel\"]],\"no-animation\"],null],\" \",[22,\"fadeClass\"]]]],[8],[1,[22,\"labelString\"],false],[9],[0,\"\\n\"],[15,\"partials/errors\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "admin/templates/components/date-picker.hbs"
    }
  });

  _exports.default = _default;
});