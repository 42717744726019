define("admin/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      if (transition.to.queryParams.error) {
        this.set('error', transition.to.queryParams.error);
      } // this will do a lot of clearout just to make sure they are totally logged out.
      // When logging out from the application, we just redirect to this page and this will
      // do any final cleanup


      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.session.logout();
      });
    },
    model: function model() {
      return Ember.RSVP.resolve(Ember.Object.create({
        email: null,
        password: null,
        remember: false
      }));
    },
    afterModel: function afterModel()
    /*model*/
    {
      this._super.apply(this, arguments);

      if (this.error) {
        this.overlay.showBanner('error', this.error, {
          title: 'Oops!'
        });
      }
    }
  });

  _exports.default = _default;
});