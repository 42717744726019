define("admin/utils/parse-uri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseURI;
  _exports.parseQuery = parseQuery;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function parseURI(string) {
    var re = new RegExp(['^(https?:)//', // protocol
    '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
    '(/{0,1}[^?#]*)', // pathname
    '(\\?[^#]*|)', // search
    '(#.*|)$'].join(''));
    var match = string.match(re);
    return match && {
      href: string,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7]
    };
  }

  function parseQuery(string) {
    var hashes = string.slice(string.indexOf('?') + 1).split('&');
    var params = {};
    hashes.map(function (hash) {
      var _hash$split = hash.split('='),
          _hash$split2 = _slicedToArray(_hash$split, 2),
          key = _hash$split2[0],
          val = _hash$split2[1];

      params[key] = decodeURIComponent(val);
    });
    return params;
  }
});