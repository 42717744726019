define("admin/mixins/social-login-controller", ["exports", "admin/utils/redirect"], function (_exports, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loginFacebook: function loginFacebook() {
        (0, _redirect.default)(window.location.origin + '/v2/auth/facebook?' + this.params());
      },
      loginGoogle: function loginGoogle() {
        (0, _redirect.default)(window.location.origin + '/v2/auth/google?' + this.params());
      },
      loginLinkedin: function loginLinkedin() {
        (0, _redirect.default)(window.location.origin + '/v2/auth/linkedin?' + this.params());
      }
    },
    params: function params() {
      var p = {
        'callback-url': window.location.origin
      };

      if (document.location.pathname === '/login') {
        p.plan_id = 2;
      } else if (this.plan) {
        p.plan_id = this.planId();
      }

      return $.param(p);
    }
  });

  _exports.default = _default;
});