define("admin/services/client", ["exports", "admin/utils/xhr-states", "admin/utils/request-log", "admin/utils/error-reporter", "admin/config/environment", "admin/utils/redirect"], function (_exports, _xhrStates, _requestLog, _errorReporter, _environment, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    host: _environment.default.API_URL || window.location.origin,
    prefix: 'v2',
    session: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    cached: function cached(method, resource, id) {
      var expires = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      return this.send(method, resource, id, null, expires || 30 * 1000);
    },
    GET: function GET() {
      return this.send.apply(this, ['GET'].concat(Array.prototype.slice.call(arguments)));
    },
    POST: function POST() {
      return this.send.apply(this, ['POST'].concat(Array.prototype.slice.call(arguments)));
    },
    PATCH: function PATCH() {
      return this.send.apply(this, ['PATCH'].concat(Array.prototype.slice.call(arguments)));
    },
    DELETE: function DELETE() {
      return this.send.apply(this, ['DELETE'].concat(Array.prototype.slice.call(arguments)));
    },
    send: function send(method, resource) {
      var _this2 = this;

      var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var expires = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var opts = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};

      var _this = this;

      var url; // allow usage of full url

      if (arguments.length == 2 && resource.indexOf('http') === 0) {
        url = resource;
      } else {
        url = this.buildUrlFor(resource, id);
      } // do caching on GET methods


      if (method === 'GET' && expires) {
        var cached = this.urlCache.read(url);

        if (cached) {
          return cached;
        }
      }

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          method: method,
          url: url,
          data: body ? JSON.stringify(body) : null,
          dataType: 'json',
          headers: _this2.headers(opts),
          success: function success(data, _textStatus, jqXHR) {
            _requestLog.default.log(method, url, jqXHR.status, jqXHR.responseJSON);

            if (opts.includeXHR) {
              return resolve([data, jqXHR]);
            } else {
              return resolve(data);
            }
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            console.error('Network request error:');
            console.error('jqXHR', JSON.stringify(jqXHR));
            console.error('textStatus', textStatus);
            console.error('errorThrown', errorThrown); // leave as function for `...arguments` below

            _errorReporter.default.breadcrumb({
              message: 'AJAX client.js error',
              category: 'action',
              data: {
                method: method,
                url: url,
                jqXHR: jqXHR,
                textStatus: textStatus,
                errorThrown: errorThrown
              }
            }); // if (jqXHR.status === 500) {
            //   redirect('/error')
            // }


            if (jqXHR.status === 503) {
              if (!(0, _redirect.default)('/maintenance')) {
                return reject(jqXHR.responseJSON, jqXHR);
              }
            }

            if (jqXHR.status === 401) {
              if (resource === 'auth' && id === 'login') {
                return;
              }

              _this.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


              (0, _redirect.default)('/login');
            }

            if (jqXHR.status === 402) {
              Ember.getOwner(_this).lookup('router:main').transitionTo('main.settings.subscription');
            }

            if (jqXHR.status === 429 && jqXHR.responseJSON.errors) {
              var emailVerifyError = jqXHR.responseJSON.errors.find(function (e) {
                return e.id == 'email-not-verified';
              });

              if (emailVerifyError) {
                _this.session.handleEmailNotVerified(emailVerifyError);
              }
            }

            _requestLog.default.log(method, url, jqXHR.status, jqXHR.responseJSON);

            return Ember.run.apply(void 0, [null, reject].concat(Array.prototype.slice.call(arguments)));
          },
          statusCode: {
            401: function _() {
              Ember.run(function () {
                if (resource === 'auth' && id === 'login') {
                  return;
                }

                _this2.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


                (0, _redirect.default)('/login');
              });
            },
            400: function _(jqXHR, _textStatus, _errorThrown) {
              Ember.run(function () {
                if (jqXHR.responseJSON.error && jqXHR.responseJSON.error === 'token_not_provided') {
                  _this2.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared


                  (0, _redirect.default)('/login');
                }
              });
            }
          }
        });
      });

      if (method === 'GET' && expires) {
        this.urlCache.write(url, null, promise, expires);
      }

      return promise;
    },
    upload: function upload(resource, id, file, callbacks) {
      var _this3 = this;

      var url = this.buildUrlFor(resource, id);
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      var token = this.get('session.token');

      if (token) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
      }

      xhr.upload.addEventListener('progress', function (e) {
        Ember.run(function () {
          if (callbacks.progress) {
            callbacks.progress(e.loaded / e.total * 100);
          }
        });
      }, false);

      var handleLoad = function handleLoad() {
        _requestLog.default.log('POST', url, xhr.status, xhr.responseJSON);

        if (xhr.status >= 200 && xhr.status < 300) {
          Ember.tryInvoke(callbacks, 'success', xhr.responseJSON);
        } else if (xhr.status >= 400 && xhr.status < 500) {
          Ember.tryInvoke(callbacks, 'failed', [xhr.status, JSON.parse(xhr.responseText)]);
        } else {
          Ember.tryInvoke(callbacks, 'error', [xhr, xhr.statusText]);
        }
      };

      xhr.onreadystatechange = function () {
        if (xhr.readyState === _xhrStates.default.DONE) {
          Ember.run.debounce(_this3, handleLoad, 10);
        }
      };

      xhr.send(file);

      if (Ember.testing) {
        Ember.Test.registerWaiter(function () {
          return xhr.readyState === 4;
        });
      }
    },
    buildUrlFor: function buildUrlFor(resource, id) {
      return [this.host, this.prefix, resource, id].filter(function (item
      /*, index*/
      ) {
        return item !== null;
      }).join('/');
    },
    headers: function headers(opts) {
      var headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      };
      var token = opts.token || this.get('session.token');

      if (token) {
        headers['Authorization'] = "Bearer ".concat(token);
      }

      return headers;
    }
  });

  _exports.default = _default;
});