define("admin/models/chart-account-template-group", ["exports", "@ember-data/model", "admin/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    description: (0, _model.attr)(),
    showChartCode: (0, _model.attr)(),
    default: (0, _model.attr)('boolean'),
    account: (0, _model.belongsTo)('account'),
    templates: (0, _model.hasMany)('chart-account-template')
  });

  _exports.default = _default;
});